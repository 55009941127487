export const EditPassword = async (password, newPassword) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/editpassword/editpassword`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              password: password,
              new_password: newPassword,
              pattern:'edit_password'
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};