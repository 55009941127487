export const UpdatePermission = async (newOptinPermission) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/service`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              userAttributes: [
                ...Object.keys(newOptinPermission).map(key => ({
                  Name: key,
                  Value: newOptinPermission[key]
                }))
              ],
            }),
            credentials: 'include'
        });

        return response;
    } catch (error) {
        return error;
    }
};