//Reacrのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

//cssのインポート
import './ResendCodePage.css'; // スタイル用のCSSファイルを作成する

//マテリアルデザインのインポート
import { Button, TextField } from '@mui/material';

//ヘルパー関数またはコンポーネントのインポート
import { isValidEmail } from '../../../utils/validation.js';
import { ResendCodeButton } from './controller/ResendCodeButton.jsx';
import ProgressSteps from '../../../componets/ProgressSteps.jsx';

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function ResendCodePage(){
  const [email, setMail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [errorCheck, setErrorCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  //次へボタンが押されたときにコンポーネントを呼び出す
  const resendCodeButton = async () => {
    const sanitizedEmail = DOMPurify.sanitize(email);
    await ResendCodeButton(sanitizedEmail,setIsLoading, setErrorMessage, setErrorCheck, navigate);
  };

  //次へボタンの許可審査
  useEffect(() => {
    if(email.length > 0){
      if (emailError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [emailError, email]);

  //入力されたメールのチェック
  useEffect(() => {
    if(email.length > 0){
      setEmailError(!isValidEmail(email));
    }
  }, [email]);

  return (
    <div className="common-page" >
      <img src={backgroundImage} alt="Background" className="common-background-image" />
      <img src={sendaiImage} alt="Sendai" className="common-logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={0}/>
      <div className="common-login-container">
        <div className="common-form-container">
          {errorCheck && 
            <div className='common-error-massage'>
              {errorMessage}
            </div>
          }
          <label htmlFor="email" className="email">メールアドレス</label>
          <TextField
            type="email"
            id="email"
            value={email}
            onChange={(e) => setMail(e.target.value)}
            placeholder="例：sendai-portal@sample.com"
            fullWidth
            error={emailError}
            className='inputStyles'
          />
          {emailError && (
              <div className='common-error-massage-text-field'>
                無効なメールアドレスです
              </div>
            )}
          <br/>
          <Button className="common-button" onClick={resendCodeButton} disabled={isButtonDisabled}>
            次へ
          </Button>
        </div>
      </div>
      {isLoading && (
        <div className="common-loading-overlay">
          <h2>認証コードの再発行中です。しばらくお待ちください。</h2>
          <div className="common-loader"></div>
        </div>
      )}
      <footer className='coomon-login-footer'>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ResendCodePage;