//コード再発行メソッド
export const resedCodePublish = async (mail) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/signup/resend_code`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              email:mail,
              pattern:'resend_code'
            }),
            credentials:'include'
        });
        return response;
    } catch (error) {
        return error;
    }
  };