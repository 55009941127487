//Reactのインポート
import React, { useState } from 'react';

//CSSのインポート
import './XIDAliginmentPage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//componentのインポート
import { xIDDelete } from './controller/XIDDeletel.jsx';

export default function XIDDeletePage({onBackButtonClick, errorTextDisplay, successXIDCancel}){
  const [isLoading, setIsLoading] = useState(false);


  //解除ボタンをクリックしたときに実行
  const deleteButtonClick = async () => {
    await xIDDelete(setIsLoading, successXIDCancel, errorTextDisplay);
  };
  
  return (
      <div className='xidalignment-page'>
        <div className='confirm-container-xid'>
          <p className='confirm-container-xid-label' style={{marginTop:'25px'}}>マイナンバー連携を解除すると、SmartPostの情報は取得できません。</p>
          <p className='confirm-container-xid-label' style={{marginBottom:'35px'}}>本当に解除してもよろしいですか？</p>
          <Button className="change" onClick={deleteButtonClick}>削除する</Button>
          <Button className="back" onClick={onBackButtonClick} style={{marginBottom: '35px'}}>戻る</Button>
        </div>
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>マイナンバー連携を解除中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
      </div>  
  );
};
