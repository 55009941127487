import { DeleteUser } from "../../../../service/DeleteUser";

export const UserDelete = async (successDelete, setIsLoading, errorTextDisplay, onBackButtonClick) => {
    try {
      setIsLoading(true);
      // const response = await fetch(`${process.env.REACT_APP_API_URL}/delete/delete`, {
      //   method: 'POST',
      //   headers: { 
      //     'Content-Type': 'application/json; charset=utf-8',
      //   },
      //   body: JSON.stringify({
      //     pattern:'delete_user'
      //   }),
      //   credentials: 'include'
      // });

      const response = await DeleteUser();

      if (response.ok) {
        successDelete();
      } else {
        errorTextDisplay('アカウントの削除に失敗しました');
        onBackButtonClick();
      }

    } catch (error) {
      errorTextDisplay('アカウントの削除に失敗しました');
      onBackButtonClick();
    }finally{
      setIsLoading(false);
    }
}