//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

//CSSのインポート
import './MailEditPage.css';
import '../Common.css';

//マテリアルデザインのインポート
import { TextField } from '@mui/material';
import { Button } from '@mui/material';

//componentのインポート
import {isValidEmail} from '../../../utils/validation.js'
import { mailChange } from './controller/MailChangeButton.jsx';
import { GetMailAddress } from './controller/GetMailAddress.jsx';
import { inputStyles, inputStylesMail } from '../../../componets/TextField.jsx'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'


function MailEditPage(){
  const [mail, setMail] = useState('');
  const [newMail, setNewMail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('メールアドレスの変更に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  const navigateToNextScreen = (user_id) => {
    navigate('/editmailconfirm' , { state: { email: newMail, user_id:user_id } } );
  };

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  //Hooks関数の呼び出し（メールアドレス取得の処理部分）
  GetMailAddress(setIsLoading, setUserInfo);

  //変更ボタンをクリックしたときに実行
  const changeMailButtonClick = async () => {
    const sanitizedEmail = DOMPurify.sanitize(mail);
    const sanitizedNewEmail = DOMPurify.sanitize(newMail);
    await mailChange(setIsLoading, setTimeout, sanitizedEmail, sanitizedNewEmail, navigateToNextScreen, setErrorMessage, setErrorCheck);
  };

  //メールアドレスが取得できた場合に変数にセットする
  useEffect(() => {
    if (userInfo) {
      setMail(userInfo.email);
    }
  }, [userInfo]);

  //入力されたメールのチェック
  useEffect(() => {
    if(newMail.length > 0){
      setEmailError(!isValidEmail(newMail));
    }
  }, [newMail]);

  //入力チェックによるボタンの活性・非活性チェック
  useEffect(() => {
    if(newMail.length === 0){
      setIsButtonDisabled(true);
    }else if(newMail.length > 0){
      if (emailError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [emailError, newMail ]);
  
    return (
        <body>
          <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
          <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
          {userInfo && (
            <div className='editmail-page'>
              <div className='common-list-container-title'>
                <label>メールアドレス変更</label>
              </div>
              <div className='common-list-container-text'>
                  <label>変更するメールアドレスを入力してください</label>
              </div>
              <div className='input-container-editmail'>
                  {errorCheck && 
                    <div className='error-massage'>
                      {errorMessage}
                    </div>
                  }
                  <label htmlFor="email">
                      現在のメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled
                    fullWidth
                    slotProps={{
                      input: {
                        sx: inputStylesMail
                      },
                    }}
                  />
                  <label htmlFor="email">
                      新しいメールアドレス
                  </label>
                  <TextField
                    type="email"
                    id="email2"
                    value={newMail}
                    onChange={(e) => setNewMail(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    helperText={emailError ? '無効なメールアドレスです' : ''}
                    placeholder="例：new_mail@sample.com"
                    fullWidth
                    error={emailError}
                    slotProps={{
                      input: {
                        sx: inputStyles
                      },
                    }}
                    className='text-field'
                  />
              </div>
              <Button className="editmail-change-button" onClick={changeMailButtonClick} disabled={isButtonDisabled}>変更する</Button>
              <Button className="editmail-back-button" onClick={navigateToAccount}>戻る</Button>
              {isLoading && (
                <div className="common-loading-overlay">
                  <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
                  <div className="common-loader"></div>
                </div>
              )}
              {isFooterVisible && (
                <footer className='common-footer'>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
              )}
            </div>
          )}
        </body>
    );
};

export default MailEditPage;