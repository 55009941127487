export const isValidEmail = (value) => {
    if (!value) return false;
    // 基本的なメールアドレスの形式をチェック
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // ドットが連続していないか、@の直前にドットがないか、ローカル部分がドットで始まらないかをチェック
    const invalidPatterns = /(\.\.|\.@|^\.|@\.)/;
    // @の前後に少なくとも1文字があるかをチェック
    const atPosition = value.indexOf('@');
    const localPart = value.substring(0, atPosition);
    const domainPart = value.substring(atPosition + 1);
    return emailRegExp.test(value) && !invalidPatterns.test(value) && value.length <= 254 && localPart.length > 0 && domainPart.length > 0;
};

//パスワードチェック（大文字・小文字・記号の10文字以上）
export const isValidPassword = (value) => {
    const passwordRegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$_%^&*()])[\w!@#$%^&*()]{10,}$/;
    return value && passwordRegExp.test(value) && value.length <= 128;
};

//パスワード＝＝パスワード再入力チェック
export const isConfirmPassword = (text, password) => {
    return text === password;
};

//カタカナのみOKにするチェック
export const isKatakana = (value) => {
    const katakanaRegExp = /^[\u30A0-\u30FF]+$/;
    return value && katakanaRegExp.test(value) && value.length <= 30;
};

// 数字のみOKにするチェック（電話番号）
export const isPhoneNumber = (value) => {
    const numericRegExp = /^\d+$/;
    // 数字のみで構成されているか、かつ長さが12文字以内かをチェック
    return value && numericRegExp.test(value) && value.length <= 13 && value.length >= 6;
};

//検証コード確認（数字6桁）
export const isCode = (value) => {
    const codeRegExp = /^\d{6}$/;
    return codeRegExp.test(value);
};

export const isValidNickname = (value) => {
    if (!value) return true;
    // ニックネームの形式をチェック（英数字、アンダースコア、ハイフン、全角の漢字、カタカナ、ひらがな、半角・全角スペースを許可）
    const nicknameRegExp = /^[a-zA-Z0-9_\-\u4E00-\u9FFF\u30A0-\u30FF\u3040-\u309F\s\u3000]+$/;
    // ニックネームの長さをチェック（例：1文字以上10文字以内）
    return nicknameRegExp.test(value) && value.length >= 2 && value.length <= 10;
};