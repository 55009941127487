import { EditMailCodeConfirm } from "../../../../service/EditMailCodeConfirm";

export const mailEditConfirm = async (setIsLoading, setTimeout, email, user_id, code, setIsComplete, setErrorMessage, setErrorCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
    try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/editmail/codeconfirm`, {
        // method: 'POST',
        // headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        // },
        // body: JSON.stringify({
        //   email: email,
        //   user_id: user_id,
        //   code: code,
        //   pattern:'edit_mail_confirm'
        // }),
        // credentials: 'include'
        // });
        const response = await EditMailCodeConfirm(email, user_id, code);

        if (response.ok) {
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }

    } catch (error) {
        setErrorMessage('メールアドレスの変更に失敗しました。');
        setErrorCheck(true);
    } finally{
        setIsLoading(false);
    }
    }, 50);
}