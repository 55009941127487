export const ReturnMyPageFirst = async (codeParam) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/mypage_browser`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${codeParam}`
      },
      body: JSON.stringify({
        redirect_uri:`${process.env.REACT_APP_PORTAL_DOMAIN}/account`,
        client_id: sessionStorage.getItem('client_id_account'),
        code_verifier: sessionStorage.getItem('code_verifier_account')
      }),
      credentials: 'include'
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const ReturnMyPage = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/returnUserInfo/mypage`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              pattern:'user_info'
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};