import { useEffect } from 'react';
import { ReturnMyPage, ReturnMyPageFirst } from '../../../../service/ReturnMypage';
import { useAuth } from '../../../../hooks/AuthContext';


export const useGetUserInfo = (setIsLoading, setUserInfo, setIsGetComplete, location, navigate) => {
  const { login } = useAuth();
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        //ユーザ情報取得中の画面を表示
        setIsLoading(true);
        //URLを取得するための初期化
        const params = new URLSearchParams(location.search);
        //認可code取得
        const codeParam = params.get('code');
        //stateを取得
        const stateParam = params.get('state');
        //セッションからstateを取得
        const sessionState = sessionStorage.getItem('state_account');
        
        //認可コードが存在する場合は以下を実行
        if (codeParam && (sessionState === stateParam)) {
            //エラーの可能性もある処理（API利用）を実行
            try {
                const response = await ReturnMyPageFirst(codeParam);
                //API取得成功し場合に以下を実行
                if(response.ok){
                  const data = await response.json();
                  setUserInfo(data.Response);
                  login();
                }else{
                  const data = await response.json();
                  console.log(data);
                  setIsGetComplete(true);
                }
            } 
            //上記try内でエラーが起こった場合には以下を実行
            catch (error) {
                //ログイン中の画面を表示を終わりにする
                setIsLoading(false);
            }
        }else{
          const response = await ReturnMyPage();
          if(response.ok){
            const data = await response.json();
            setUserInfo(data.Response);
            login();
          }else{
            const data = await response.json();
            console.log(data);
            setIsGetComplete(true);
          }
        }
      } catch (error) {
        setIsGetComplete(true);
      } finally {
        //最後に利用していたセッションストレージをすべて空にする
        sessionStorage.clear();
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [location, navigate, login]);
};