//ユーザ情報登録
export const RegistNickName = async (newNickName) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/NickName`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              userAttributes: [
                { Name: "nickname", Value: newNickName},
              ],
              pattern:'regist_info'
            }),
            credentials: 'include'
        });
        //リクエスト元に情報を返す
        return response;
    } catch (error) {
      return error;
    }
  };