export const UpdateIcon = async (defaultFlag, defaultImage, base64Image) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/changeicon`, {
            method: 'POST',
            headers: { 
            'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              image:defaultFlag ? defaultImage : base64Image,
              defaultFlag:defaultFlag
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};