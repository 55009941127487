// 画像の配列を定義
const images = [
    `${process.env.REACT_APP_S3_DOMAIN}/user_icons/shirokuma.png`,
    `${process.env.REACT_APP_S3_DOMAIN}/user_icons/kuma.png`,
    `${process.env.REACT_APP_S3_DOMAIN}/user_icons/kitsune.png`,
    `${process.env.REACT_APP_S3_DOMAIN}/user_icons/saru.png`
];

// ランダムな画像を取得する関数
export function getRandomImage() {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
}