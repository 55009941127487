//Reactのインポート
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

//CSSのインポート
import './AccountPage.css';

//マテリアルデザインのインポート
import { Box } from '@mui/material';
import Divider from '@mui/material/Divider';
import KeyIcon from '@mui/icons-material/Key';
import ListItem from '@mui/material/ListItem';
import ModeIcon from '@mui/icons-material/Mode';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import PersonIcon from '@mui/icons-material/Person';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

//componentのインポート
import { useGetUserInfo } from './controller/GetUserInfo.jsx';
import { SetUserInfo } from './controller/SetUserInfo.jsx';

//画像のインポート
import backgroundImage from '../../../assets/background-image.webp';
import footerImage from '../../../assets/account_footer.png';


function AccountPage(){
  //画面遷移するための機能の初期化
  const navigate = useNavigate();
  //各種ページに飛ばすメソッド
  const ToNavigation = (toNavigate) => {
    navigate(toNavigate);
  };

  //クエリパラメータを取得するためのLocation初期化
  const location = useLocation();

  //Cookiになにもないときに表示する文章を格納
  const redirectText = 'セッションが切れているか、アカウントがありません。';
  //取得したuserInfoの格納
  const [userInfo, setUserInfo] = useState(null);
  //ローディング判定フラグ
  const [isLoading, setIsLoading] = useState(true);
  //ユーザの画像を格納
  const [userImage, setUserImage] = useState(null);
  //ユーザの名前を格納
  const [userName, setUserName] = useState(null);
  //ユーザ情報取得が完了したかの判定フラグ
  const [isGetComplete, setIsGetComplete] = useState(false);

  //Hooks関数の呼び出し（ユーザ情報取得の処理部分）
  useGetUserInfo(setIsLoading, setUserInfo, setIsGetComplete, location, navigate);
  //Hooks関数の呼び出し（ユーザ情報セットの処理部分）
  SetUserInfo(userInfo, setUserName, setUserImage);

  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="side"></img>
      {userInfo && (
      <div className='account-page'>
        <Box
          className='icon-image-top'
          component="img"
          alt="The house from the offer."
          src={userImage}
          onClick={() => ToNavigation('/editimage')}
        />
        <div className='name-text'>
          <label className='name-label'>
            {userName}
          </label>
          <IconButton onClick={() => ToNavigation('/editnickname')}>
            <ModeIcon/>
          </IconButton>
        </div>
        <div className='account-page-list-container'>
          {userInfo.providerName === 'Cognito' && (
            <ListItem button onClick={() => ToNavigation('/editmail')} className='list-design'>
              <ListItemIcon>
                <MailOutlineIcon />
              </ListItemIcon>
              <ListItemText primary="メールアドレス変更" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon className='account-list-button-color'/>
              </IconButton>
            </ListItem>
          )}
          {userInfo.providerName === 'Cognito' && (
            <Divider className='driver-design'/>
          )}
          {userInfo.providerName === 'Cognito' && (
            <ListItem button onClick={() => ToNavigation('/editpassword')} className='list-design'>
              <ListItemIcon>
                <KeyIcon />
              </ListItemIcon>
              <ListItemText primary="パスワード変更" />
              <IconButton edge="end" aria-label="chevron">
                <ChevronRightIcon className='account-list-button-color' />
              </IconButton>
            </ListItem>
          )}
          {userInfo.providerName === 'Cognito' && (
            <Divider className='driver-design'/>
          )}
          <ListItem button onClick={() => ToNavigation('/editinfo')} className='list-design'>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="プロフィール" />
            <IconButton edge="end" aria-label="chevron">
              <ChevronRightIcon className='account-list-button-color' />
            </IconButton>
          </ListItem>
          <Divider className='driver-design'/>
          <ListItem button onClick={() => ToNavigation('/xidalignment')} className='list-design'>
            <ListItemIcon>
              <FolderSharedIcon />
            </ListItemIcon>
            <ListItemText 
              primary="マイナンバーカード連携" />
            <IconButton edge="end" aria-label="chevron">
              <ChevronRightIcon className='account-list-button-color'/>
            </IconButton>
          </ListItem>
          <Divider className='driver-design'/>
          <ListItem button onClick={() => ToNavigation('/permission_service')} className='list-design'>
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText 
              primary="連携サービス管理" />
            <IconButton edge="end" aria-label="chevron">
              <ChevronRightIcon className='account-list-button-color'/>
            </IconButton>
          </ListItem>
          <Divider className='driver-design'/>
          <ListItem button onClick={() => ToNavigation('/delete')} className='list-design'>
            <ListItemIcon>
            <ManageAccountsIcon />
            </ListItemIcon>
            <ListItemText primary="アカウント削除" />
            <IconButton edge="end" aria-label="chevron">
              <ChevronRightIcon className='account-list-button-color' />
            </IconButton>
          </ListItem>
        </div>
        <footer className='custom-footer'>
          <img src={footerImage} alt="The house from the offer" className="responsive-image"/>
          <div className='footer-text'>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
          </div>
        </footer>
      </div>
      )}
      {isLoading && (
        <div className="common-loading-overlay">
          <div className="common-loader"></div>
        </div>
      )}
      {(!userInfo && isGetComplete) &&(
        <div className='account-page'>
          <lable className='error-text'>{redirectText}</lable>
          <footer className='custom-footer'>
              <img src={footerImage} alt="The house from the offer" className="responsive-image" />
              <div className='footer-text'>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
              </div>
          </footer>
        </div>
      )}
    </body>
  );
};

export default AccountPage;