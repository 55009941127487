//Reactの標準パッケージをインポート
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';

//マテリアルデザインのインポート
import { Button, TextField } from '@mui/material';

//コンポーネントまたはヘルパー関数のインポート
import { isCode } from '../../../utils/validation.js'
import { ResendCodeLink } from './controller/ResendCodeLink.jsx'
import { CodeConfirmButton } from './controller/CodeConfirmButton.jsx'
import { inputStylesMail, inputStyles } from '../../../componets/TextField.jsx'
import ProgressSteps from '../../../componets/ProgressSteps.jsx';

//CSSのインポート
import '../LoginCommon.css';
import './ResendCodeConfirm.css'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function ResendConfirmPage(){
  const location = useLocation();
  const { email } = location.state || {};
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [errorCheck, setErrorCheck] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();

  //入力されたコードのチェック
  useEffect(() => {
    if (codeError) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [codeError,code]);

  //入力されたコードのチェック
  useEffect(() => {
    if(code.length > 0){
      setCodeError(!isCode(code));
    }
  }, [code]);

  //emailが空だったらログインホームに戻す（/resendconfirmに直接アクセスされないように）
  useEffect(() => {
    if (!email) {
      navigate('/oauth2/authorize');
    }
  }, [email, navigate]);

  //次へボタンが押されたときにヘルパー関数を呼び出す
  const confirmButton = async () => {
    //サニタイズ処理
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedCode = DOMPurify.sanitize(code);
    await CodeConfirmButton(setIsLoading, sanitizedEmail, sanitizedCode, setErrorMessage, setErrorCheck, navigate);
  };

  //認証メールを再送信するリンクが押されたときにヘルパー関数を呼び出す
  const resendCodeLink = async () => {
    const sanitizedEmail = DOMPurify.sanitize(email);
    await ResendCodeLink(setIsLoading, sanitizedEmail, setErrorMessage, setErrorCheck, navigate);
  };

  return (
    <div className="common-page">
      <img src={backgroundImage} alt="Background" className="common-background-image" />
      <img src={sendaiImage} alt="Sendai" className="common-logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={1}/>
      <div className="common-login-container">
        <div className="common-form-container">
          {errorCheck && 
            <div className='common-error-massage'>
              {errorMessage}
            </div>
          }
          <div className='resend-code-confirm-message'>
              <label >下記メールアドレスに確認コードを再送信しました。送信された確認コードを入力してください。</label>
          </div>
          <label htmlFor="email" className="email">送信先メールアドレス</label>
          <TextField
              type="email"
              id="email"
              value={email}
              onChange={() => {}}
              fullWidth
              disabled
              slotProps={{
                input: {
                    sx: inputStylesMail
                },
               }}
          />
          <label htmlFor="password">認証コード</label>
          <TextField
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              fullWidth
              slotProps={{
                input: {
                    sx: inputStyles
                },
              }}
          />
          <Link className='common-link' onClick={resendCodeLink}>
            認証メールを再送信する
          </Link>
          <Button className="common-button" onClick={confirmButton} disabled={isButtonDisabled}>次へ</Button>
        </div>
      </div>
      {isLoading && (
        <div className="common-loading-overlay">
          <h2>ログイン中です。しばらくお待ちください。</h2>
          <div className="common-loader"></div>
        </div>
      )}
      <footer className='common-login-footer'>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default ResendConfirmPage;