//SENDAIポータル 2回目のログイン
export const requestLogin = async () => {
    //セッションからアクセス時のクエリパラメータを取得
    const storedQueryString = sessionStorage.getItem('queryString');
    //セッションからログインしたプロバイダーの取得
    const identityProvider = sessionStorage.getItem('provider');
    //セッションからログインしたプロバイダーの取得
    const codeVerifierAccount = sessionStorage.getItem('code_verifier_account');
    //セッションからログインしたプロバイダーの取得
    const stateAccount = sessionStorage.getItem('state_account');
    //セッションからログインしたプロバイダーの取得
    const client_id = sessionStorage.getItem('client_id');
    //ログインするためのURLを作成
    const authUrl0 = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?${storedQueryString}&identity_provider=${identityProvider}`
    //最後に利用していたセッションストレージをすべて空にする
    sessionStorage.clear();
    //codeVerifierAccountを再度設定する
    codeVerifierAccount !== null && sessionStorage.setItem('code_verifier_account', codeVerifierAccount);
    //state_accountを再度設定する
    stateAccount !== null && sessionStorage.setItem('state_account', stateAccount);
    //client_idを再度設定する
    client_id !== null && sessionStorage.setItem('client_id_account', client_id);

    //クライアントに認可コードを返すためのログイン
    window.location.href = authUrl0;
};