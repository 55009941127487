import { resedCodePublish } from '../../../../service/RecendCode.js';

export const ResendCodeLink = async (setIsLoading, email, setErrorMessage, setErrorCheck, navigate) => {
    setIsLoading(true);
    try {
        const response = await resedCodePublish(email);
        if (response.ok) {
            const responseData = await response.json();
            console.log('User registration successful', responseData);
            navigate('/resendconfirm', { state: { email: email } });
        } else {
            const errorData = await response.json();
            console.error('Failed to register user:', errorData);
            setErrorMessage(errorData.errors.message);
            setErrorCheck(true);
         }
    } catch (error) {
      console.error('Error during sign-in:', error);
      setErrorMessage('検証コードの再送に失敗しました。');
      setErrorCheck(true);
    } finally {
      setIsLoading(false);
    }
};