import { providerSignIn } from '../../../../service/SocialLoginService.js';
import { setLang } from '../../../../service/LangSet.js';
    
export const ProviderLoginButton = async (provider, setIsLoading) => {
    setIsLoading(true);
    if(provider === 'Cognito'){
        //LangをCookieにセットする
        const response = await setLang();
    }
    //言語設定に失敗しても成功してもCognitoログイン画面は表示する
    sessionStorage.setItem('provider', provider);
    //プロバイダーのログイン画面を表示
    providerSignIn(provider);
}