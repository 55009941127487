import { generateCodeVerifier, generateCodeChallenge } from '../utils/PKCEService.js';
import { generateState } from '../utils/StateCreate.js';

//1回目のログイン
export const providerSignIn = (provider) => {
    //PKCE用のコードチャレンジ作成
    const verifier = generateCodeVerifier();
    const challenge = generateCodeChallenge(verifier);
    // セッションストレージにverifierを保存（トークン取得に利用するため）
    sessionStorage.setItem('code_verifier', verifier);
    //stateを作成
    const state = generateState(64);
    sessionStorage.setItem('state', state);
    //IDトークンのNoneにこれを入れる（IDトークン検証に利用可能）
    const nonce = 'sendai-portal';

    //セッションからアクセス時のクエリパラメータを取得
    const storedQueryString = sessionStorage.getItem('queryString');
    //仙台ポータルトップページログイン画面をリダイレクト先に設定
    const newRedirectUri = `${process.env.REACT_APP_PORTAL_DOMAIN}/oauth2/authorize`;
    //アクセス元のリダイレクト先を上記で上書きする（アプリクライアント・スコープを同様にするため）
    const updatedUri = storedQueryString.replace(/(redirect_uri=)[^&]+/, `$1${newRedirectUri}`);
    //StringをURLに変換
    const urlParams = new URLSearchParams(updatedUri);
    //URLにstateが含まれていたら削除
    const defaultState = urlParams.has('state');
    if(defaultState){
      urlParams.delete('state');
    }
    //URLにnonceが含まれていたら削除
    const defaultNonce = urlParams.has('nonce');
    if(defaultNonce){
      urlParams.delete('nonce');
    }
    //URLにcode_challengeが含まれていたら削除
    const defaultCodeChallenge = urlParams.has('code_challenge');
    if(defaultCodeChallenge){
      urlParams.delete('code_challenge');
    }
    //URLにcode_challenge_methodが含まれていたら削除
    const defaultCodeChallengeMethod = urlParams.has('code_challenge_method');
    if(defaultCodeChallengeMethod){
      urlParams.delete('code_challenge_method');
    }

    //URLをStringに変換
    const neoUpdatedUri = urlParams.toString();


    //Cognitoに依頼するURLを作成
    const authUrl = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?` +
      `${neoUpdatedUri}&` +
      `identity_provider=${provider}&` +
      `state=${state}&` +
      `nonce=${nonce}&` +
      `code_challenge=${challenge}&` +
      `code_challenge_method=S256`;

    //ログイン画面を表示
    window.location.href = authUrl;
 };

 export const xIDSignIn = (provider) => {
  //PKCE用のコードチャレンジ作成
  const verifier = generateCodeVerifier();
  const challenge = generateCodeChallenge(verifier);
  // セッションストレージにverifierを保存（トークン取得に利用するため）
  sessionStorage.setItem('code_verifier_xID', verifier);
  //stateを作成
  const state = generateState(64);
  sessionStorage.setItem('state_xid', state);
  //IDトークンのNoneにこれを入れる（IDトークン検証に利用可能）
  const nonce = 'sendai-portal';
  const clientId = process.env.REACT_APP_XID_CLIENT;
  const responseType = 'code';
  const scope = 'openid profile email aws.cognito.signin.user.admin';
  const redirectUri = `${process.env.REACT_APP_PORTAL_DOMAIN}/xidalignment`;
  const identityProvider = provider;

  const codeChallenge = challenge;
  const codeChallengeMethod = 'S256';

  const authUrl = `${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?` +
    `client_id=${clientId}&` +
    `response_type=${responseType}&` +
    `scope=${encodeURIComponent(scope)}&` +
    `redirect_uri=${encodeURIComponent(redirectUri)}&` +
    `identity_provider=${identityProvider}&` +
    `state=${state}&` +
    `nonce=${nonce}&` +
    `code_challenge=${codeChallenge}&` +
    `code_challenge_method=${codeChallengeMethod}`;

  //Auth0のログイン画面を表示
  window.location.href = authUrl;
};


//クエリなどなにも設定せずに本サイトにアクセス来た場合にクエリを設定して、ログイン画面に遷移させる
export const signinForAccount = () => {
  //PKCE用のコードチャレンジ作成
  const verifier = generateCodeVerifier();
  const challenge = generateCodeChallenge(verifier);
  // セッションストレージにverifierを保存（トークン取得に利用するため）
  sessionStorage.setItem('code_verifier_account', verifier);

  const clientId = process.env.REACT_APP_PORTAL_CLIENT;
  const responseType = 'code';
  const scope = 'openid profile email aws.cognito.signin.user.admin';
  const redirectUri = `${process.env.REACT_APP_PORTAL_DOMAIN}/account`;
  const state = generateState(64);
  sessionStorage.setItem('state_account', state);
  const nonce = 'sendai-portal';

  const authUrl = `${process.env.REACT_APP_PORTAL_DOMAIN}/oauth2/authorize/?` +
    `client_id=${clientId}&` +
    `response_type=${responseType}&` +
    `scope=${encodeURIComponent(scope)}&` +
    `redirect_uri=${encodeURIComponent(redirectUri)}&` +
    `state=${state}&` +
    `nonce=${nonce}&` +
    `code_challenge=${challenge}&` +
    `code_challenge_method=S256`;

  //Auth0のログイン画面を表示
  window.location.href = authUrl;
};