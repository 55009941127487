export const SignInxID = async (codeParam) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/xIDsignin/xIDsignin`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${codeParam}`
            },
            body: JSON.stringify({
                redirect_uri: `${process.env.REACT_APP_PORTAL_DOMAIN}/xidalignment`,
                pattern:'xid',
                code_verifier: sessionStorage.getItem('code_verifier_xID')
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};