import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './RegistInfoPage.css';
import '../../AccountPage/Common.css';
import { TextField, Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import DOMPurify from 'dompurify';
import { inputStyles } from '../../../componets/TextField.jsx'

import {isKatakana, isPhoneNumber} from '../../../utils/validation.js'
import { RegistButton } from './controller/RegistButton.jsx'
import backgroundImage from '../../../assets/backgroun-image.png';

function RegistInfoPage(){

  const location = useLocation();
  const { nickname } = location.state || {};
  const [family_name, setFamilyName] = useState('');
  const [given_name, setGivenName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [familyNameError, setFamilyNameError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  //登録ボタンが押されたときにコンポーネントを呼び出す
  const registButton = async () => {
    //サニタイズ処理
    const sanitizedFamilyName = DOMPurify.sanitize(family_name);
    const sanitizedGivenName = DOMPurify.sanitize(given_name);
    const sanitizedPhoneNumber = DOMPurify.sanitize(phone_number);
    await RegistButton(setIsLoading, setIsButtonDisabled, sanitizedFamilyName, sanitizedGivenName, nickname, sanitizedPhoneNumber);
  };

  //登録ボタンの活性・非活性の変更処理
  useEffect(() => {
    //一つでも未入力があれば非活性にする
    if(family_name.length === 0 || given_name.length === 0){
      setIsButtonDisabled(true); //非活性
    }
    //一つでも入力があれば、エラーチェックを実行
    else if(family_name.length > 0 || given_name.length > 0){
      //一つでもエラーがある場合
      if (familyNameError || givenNameError || phoneNumberError) {
        setIsButtonDisabled(true); //非活性
      } 
      //一つもエラーがない場合
      else {
        setIsButtonDisabled(false); //活性
      }
    }
  }, [familyNameError, givenNameError, phoneNumberError, family_name.length, given_name.length, phone_number.length]);

  //入力されたフリガナ（姓）のチェック
  useEffect(() => {
    if(family_name.length > 0){
      setFamilyNameError(!isKatakana(family_name));
    }
  }, [family_name]);

  //入力されたフリガナ（名）のチェック
  useEffect(() => {
    if(given_name.length > 0){
      setGivenNameError(!isKatakana(given_name));
    }
  }, [given_name]);

  //入力された電話番号のチェック
  useEffect(() => {
    if(phone_number.length > 0){
      setPhoneNumberError(!isPhoneNumber(phone_number));
    }else if(phone_number.length === 0){
      setPhoneNumberError(false);
    }
  }, [phone_number]);
  
  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
        <div className='regist-page'>
          <div className='common-list-container-title'>
            <label>ユーザ情報登録</label>
          </div>
          <div className='common-list-container-text'>
            <label>プロフィール情報を登録してください</label>
          </div>
          <div className='input-container-registinfo'>
            <label htmlFor="email">
                フリガナ（姓） <span className='registinfo-label-color-required'>必須</span>
            </label>
              <TextField
                type="text"
                id="famiry_name"
                value={family_name}
                onChange={(e) => setFamilyName(e.target.value)}
                placeholder="ヤマダ"
                fullWidth
                error={familyNameError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
              />
              {familyNameError && (
                <FormHelperText error className='form-helper-text'>
                  カタカナを入力してください（30文字以内）
                </FormHelperText>
              )}
              <label htmlFor="email">
                  フリガナ（名）<span className='registinfo-label-color-required'>必須</span>
              </label>
              <TextField
                type="text"
                id="given_name"
                value={given_name}
                onChange={(e) => setGivenName(e.target.value)}
                placeholder="タロウ"
                fullWidth
                error={givenNameError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
              />
              {givenNameError && (
                <FormHelperText error className='form-helper-text'>
                  カタカナを入力してください（30文字以内）
                </FormHelperText>
              )}
              <label htmlFor="email" >
                  電話番号 <span className='registinfo-label-color-option'>　任意</span>
              </label>
              <TextField
                type="tel"
                id="phone_number"
                value={phone_number}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="ハイフン無し（09012345678)"
                fullWidth
                error={phoneNumberError}
                slotProps={{
                  input: {
                    sx: inputStyles
                  },
                }}
              />
              {phoneNumberError && (
                <FormHelperText error className='form-helper-text'>
                  数字のみで正しい電話番号を入力してください
                </FormHelperText>
              )}
              <Button className="regist-button" onClick={registButton} disabled={isButtonDisabled}>登録する</Button>
            </div>
          {isLoading && (
            <div className="common-loading-overlay">
              <h2>ユーザー情報を登録中です。しばらくお待ちください。</h2>
              <div className="common-loader"></div>
            </div>
          )}
          <footer className='common-login-footer'>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer>
      </div>   
    </body> 
  );
};

export default RegistInfoPage;