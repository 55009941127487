//Reacrのインポート
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

//cssのインポート
import './OptinPage.css'; 

//マテリアルデザインのインポート
import { Button } from '@mui/material';
import { IOSSwitch } from '../../../componets/ToggleSwitch.jsx' 

//ヘルパー関数のインポート
import { OptinButton } from './controller/OptinButton.jsx';

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';



function OptoinPage({ onCancel }){
  const location = useLocation();
  const { userInfo_flag, nickname, optin, optin_tag_name, image_url, service_name } = location.state || {};
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  //許可するかのON・OFFトグルボタンチェックフラグ
  const [checked, setChecked] = useState(false);

  //トグルボタンが押されたときのフラグチェンジメソッド
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  
  //許可ボタンが押されたときにコンポーネントを呼び出す
  const optinButton = async () => {
    await OptinButton(setIsLoading, userInfo_flag, nickname, navigate, optin_tag_name);
  };

  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
      <div className="common-page">
        {(optin === 'null' || optin === '0') && (
          <img src={image_url} alt="Sendai" className="optoin-logo" />
        )}
        {(optin === 'null' || optin === '0') && (
          <label style={{marginTop:'10px', marginLeft:'5px', fontSize:'15px', color:'#000'}}>{service_name}</label>
        )}
        {(optin === 'null' || optin === '0') && (
          <div className="common-login-container">
            <div className="common-form-container">
              <label style={{marginTop:'60px', marginBottom:'30px' ,fontSize:'14px'}}>許可が必要な項目</label>
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                <label style={{ fontSize: '14px',margin: '0px' }}>プロフィール情報（必須）</label>
                <IOSSwitch checked={checked} onChange={handleChange} />
              </div>
              <hr style={{marginTop:'55px', marginBottom:'35px' ,border: '0.1px solid rgba(220, 220, 220, 0.7)', width:'100%'}}></hr>
              <label style={{marginTop:'20px', fontWeight:'600', fontSize:'14px'}}>注意事項</label>
              <label style={{marginTop:'2px', marginLeft:'5px', fontSize:'14px'}}>1.～～～～</label>
              <label style={{marginTop:'2px', marginLeft:'5px', fontSize:'14px'}}>2.～～～～～</label>
              <Button className="optoin-button" onClick={optinButton} disabled={!checked} style={{margin:'auto', marginTop:'40px'}}>許可する</Button>
              <Link to="/oauth2/authorize" onClick={onCancel} style={{ marginTop:'20px',color:'#000', fontWeight:'500' ,textDecoration: 'none', justifyContent: 'center', textAlig: 'center', width: '100%', fontSize:'14px' }}>
                キャンセル
              </Link>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>設定中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
        <footer className='common-login-footer'>
          <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
      </div>
    </body>
  );
};

export default OptoinPage;