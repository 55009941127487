import { useEffect } from 'react';
import { SignInxID } from '../../../../service/SignInxID';

export const XIDCodeProcess = (location, setIsLoading, setErrorMessage, setErrorCheck) => {  
    useEffect(() => {
      //関数の定義
      const fetchToken = async () => {
        //URLを取得するための初期化
        const params = new URLSearchParams(location.search);
        //認可code取得
        const codeParam = params.get('code');
        //state取得
        const stateParam = params.get('state');
        //セッションからstateを取得
        const sessionState = sessionStorage.getItem('state_xid');
        //認可コードがあれば以下実行
        if (codeParam && (sessionState === stateParam)){ 
            setIsLoading(true);
            try {
                // const response = await fetch(`${process.env.REACT_APP_API_URL}/xIDsignin/xIDsignin`, {
                //     method: 'POST',
                //     headers: { 
                //         'Content-Type': 'application/json; charset=utf-8',
                //         'Authorization': `Bearer ${codeParam}`
                //     },
                //     body: JSON.stringify({
                //         redirect_uri: `${process.env.REACT_APP_PORTAL_DOMAIN}/xidalignment`,
                //         pattern:'xid',
                //         code_verifier: sessionStorage.getItem('code_verifier_xID')
                //     }),
                //     credentials: 'include'
                // });
                const response = await SignInxID(codeParam);
                if (response.ok) {
                    sessionStorage.setItem('x_flag', '1');
                } else {
                    const errorData = await response.json();
                    setErrorMessage(errorData.errors.message);
                    setErrorCheck(true);
                }
            } catch (error) {
                setErrorMessage('マイナンバー連携に失敗しました。');
                setErrorCheck(true);
            } finally{
                setIsLoading(false);
            }
        }
      };
      //上記関数を実行（非同期で実行するためにこのようなやりかたとしている）
      fetchToken();
    }, [location]);
  };