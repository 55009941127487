import { EditMail } from "../../../../service/EditMail";

export const mailChange = async (setIsLoading, setTimeout, mail, newMail, navigateToNextScreen, setErrorMessage, setErrorCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        const response = await EditMail(mail, newMail);
        if (response.ok) {
          const responseData = await response.json();
          navigateToNextScreen(responseData.Response.userId);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }
      } catch (error) {
        setErrorMessage('メールアドレスの変更に失敗しました。');
      } finally{
        setIsLoading(false);
      }
    }, 50);
}