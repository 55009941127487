// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useAuth } from './hooks/AuthContext.js';

// const PrivateRoute = ({ element, ...rest }) => {
//   const { isAuthenticated } = useAuth();

//   return isAuthenticated ? element : <Navigate to="/" />;
// };

// export default PrivateRoute;


import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, ...rest }) => {
  // セッションストレージから認証状態を取得
  const isAuthenticated = sessionStorage.getItem('isAuthenticated') === 'true';

  return isAuthenticated ? element : <Navigate to="/" />;
};

export default PrivateRoute;