export const ReturnMail = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/returnUserInfo/email`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              pattern:'user_info_email'
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};