//オプトインの確認
export const checkOptoin = async (codeParam) => {
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}/checkoptin`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': `Bearer ${codeParam}`
      },
      body: JSON.stringify({
        redirect_uri:`${process.env.REACT_APP_PORTAL_DOMAIN}/oauth2/authorize`,
        pattern:'optin_check',
        client_id: sessionStorage.getItem('client_id'),
        code_verifier: sessionStorage.getItem('code_verifier')
      }),
      credentials: 'include'
    });
    return response;
  }catch(error){
    return error;
  }
}

//オプトイン更新
export const setOptoin = async (optin_tag_name) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/optin`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        pattern:'regist_info',
        userAttributes: [
          {
              Name: optin_tag_name,
              Value: "1"
          }
        ]
      }),
      credentials:'include'
    });
    return response;
  } catch (error) {
    return error;
  }
};