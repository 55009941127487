import { CodeConfirm } from '../../../../service/CodeConfirm.js';

export const CodeConfirmButton = async (setIsLoading, email, code, setErrorMessage, setErrorCheck, navigate) => {
    setIsLoading(true);
    try {
        const response = await CodeConfirm(email, code);
        if (response.ok) {
          navigate('/complete');
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }
    } catch (error) {
      setErrorMessage('検証コードの確認に失敗しました。');
      setErrorCheck(true);
    } finally {
      setIsLoading(false);
    }
};