// userLogin.js
import { useEffect } from 'react';
import { ReturnService } from '../../../../service/ReturnService';

export const GetPemission = (setIsLoading, setUserInfo, setErrorMessageGetPermission, setErrorCheckGetPermission) => {
    useEffect(() => {
        const fetchUserInfo = async () => {
          try {
            const response = await ReturnService();
            if(response.ok){
                const data = await response.json();
                setUserInfo(data.Response);
            }else{
                const errorData = await response.json();
                setErrorMessageGetPermission(errorData.errors.message);
                setErrorCheckGetPermission(true);
            }
          } catch (error) {
            setErrorMessageGetPermission(error.message);
            setErrorCheckGetPermission(true);
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchUserInfo();
    }, []);
};