//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//CSSのインポート
import '../Common.css';
import './PermissionServicePage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//componentのインポート
import { IOSSwitch } from '../../../componets/ToggleSwitch.jsx';
import { GetPemission } from './controller/GetPermission.jsx';

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'

//マテリアルデザインのインポート
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { updatePermission } from './controller/UpdatePermission.jsx';

function MailEditPage(){
  const [changeButtonCheck, setChangeButtonCheck] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('メールアドレスの変更に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const [currentOptinPermission, setCurrentOptinPermission] = useState({});
  const [newOptinPermission, setNewOptinPermission] = useState({});
  const [errorMessagePermission, setErrorMessageGetPermission] = useState('');
  const [errorCheckGetPermission, setErrorCheckGetPermission] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  //Hooks関数の呼び出し（プロフィール情報取得の処理部分）
  GetPemission(setIsLoading, setUserInfo, setErrorMessageGetPermission, setErrorCheckGetPermission);

  //変更ボタンをクリックしたときに実行
  const changePermissionButtonClick = async () => {
    await updatePermission(setIsLoading, setIsComplete ,newOptinPermission, setErrorMessage, setErrorCheck);
  };
  

  //UserInfoを変数に代入
  useEffect(() => {
    if (userInfo) {
      userInfo.forEach((item, index) => {
        setCurrentOptinPermission(prevState => ({
          ...prevState,
          [item.cognito_tag]: item.optin_check,
        }));
        setNewOptinPermission(prevState => ({
          ...prevState,
          [item.cognito_tag]: item.optin_check,
        }));
      });
    }
  }, [userInfo]);

  //トグルボタンが押されたときのフラグチェンジメソッド
  const handleChange = (tag, check) => {
    setChangeButtonCheck(1);
    const newCheck = check === '1' ? '0' : '1';
    setNewOptinPermission(prevState => ({
      ...prevState,
      [tag]: newCheck,
    }));
  };

  //入力チェックによるボタンの活性・非活性チェック
  useEffect(() => {
    if (changeButtonCheck === 1) {
      //変更前と変更後で同じであるか確認
      if (JSON.stringify(currentOptinPermission) !== JSON.stringify(newOptinPermission)) {
        //違う場合のみ変更するボタンを活性化
        setIsButtonDisabled(false);
      } else {
        //同じ場合は変更するボタンを非活性
        setIsButtonDisabled(true);
      }
    }
  }, [newOptinPermission]);


  // async function optinChange() {
  //   setIsLoading(true);
  //   setTimeout( async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/service`, {
  //         method: 'POST',
  //         headers: { 
  //           'Content-Type': 'application/json; charset=utf-8',
  //         },
  //         body: JSON.stringify({
  //           userAttributes: [
  //             ...Object.keys(newOptinPermission).map(key => ({
  //               Name: key,
  //               Value: newOptinPermission[key]
  //             }))
  //           ],
  //         }),
  //         credentials: 'include'
  //       });
  
  //       if (response.ok) {
  //         setIsComplete(true);
  //       } else {
  //         setErrorMessage('連携サービスの更新に失敗しました。');
  //         setErrorCheck(true);
  //       }
  
  //     } catch (error) {
  //       setErrorMessage('連携サービスの更新に失敗しました。');
  //       setErrorCheck(true);
  //     } finally{
  //       setIsLoading(false);
  //     }
  //   }, 50);
  // }
  
    return (
        <body>
          <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
          <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
          {userInfo && (
            <div className='permission-page'>
                <div className='common-list-container-title'>
                    <label>連携サービス管理</label>
                </div>
                {!isComplete && (
                  <div className='common-list-container-text'>
                    {errorCheck && 
                        <div className='error-massage'>
                            {errorMessage}
                        </div>
                    }
                    <label>アカウント情報を連携するサービスの管理ができます。</label>
                  </div>
                )}
                {isComplete && (
                  <div className='common-list-container-text'>
                    <label>連携サービスの変更が完了しました。</label>
                  </div>
                )}
                {!isComplete && (
                  <div className='permission-page-list-container'>
                    {userInfo.map((item, index) => (
                      <React.Fragment key={index}>
                        {newOptinPermission && (
                          <ListItem className='list-item'>
                            <ListItemIcon className='list-icon'>
                              <div className="current-icon-display">
                                <img src={item.image_url} alt="single-icon" className="icon-image" />
                              </div>
                            </ListItemIcon>
                            <ListItemText className='list-item-text' primary={item.service_name} />
                            <IconButton onClick={() => handleChange(item.cognito_tag, newOptinPermission[item.cognito_tag])} edge="end" aria-label="chevron">
                              <IOSSwitch checked={newOptinPermission[item.cognito_tag] === '1'} />
                            </IconButton>
                          </ListItem>
                        )}
                        {(userInfo.length - 1) !== index &&(
                          <Divider className='driver-design' />
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                )}
                {!isComplete && (
                  <Button className="permission-change-button" onClick={changePermissionButtonClick} disabled={isButtonDisabled}>変更する</Button>
                )}
                {!isComplete && (
                  <Button className="permission-back-button" onClick={navigateToAccount}>戻る</Button>
                )}
                {isComplete && (
                  <Button className="permission-back-button-iscomplete" onClick={navigateToAccount}>戻る</Button>
                )}
                {isLoading && (
                  <div className="common-loading-overlay">
                    <h2>連携サービスを変更中です。しばらくお待ちください。</h2>
                    <div className="common-loader"></div>
                  </div>
                )}
                <footer className='common-footer'>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
                </footer> 
            </div>
          )}
        </body>
    );
};

export default MailEditPage;