import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Button } from '@mui/material';
import { CodeProcess } from './controller/CodeProcess.jsx';
import { ProviderLoginButton } from './controller/SocialLogin.jsx';

//デザインのインポート
import './LoginPage.css';
import '../LoginCommon.css';

//画像のインポート
import backgroundImage from '../../../assets/background-image.webp';
import sendaiImage from '../../../assets/sendai.png';
import googleImage from '../../../assets/google.png';
import yahooImage from '../../../assets/yahoo.png';
import appleImage from '../../../assets/apple.png';
import sendaiportalImage from '../../../assets/sendai-app.png';

function LoginPage(){
  //ローディング画面の表示可否フラグ
  const [isLoading, setIsLoading] = useState(false);
  //クエリパラメータを取得するためのLocation初期化
  const location = useLocation();
  //Hooks関数の呼び出し（認可コードの処理部分）
  CodeProcess(location, setIsLoading);

  return (
      <div>
        <div className="common-page">
          <img src={backgroundImage} alt="Background" className="common-background-image" />
          <img src={sendaiImage} alt="Sendai" className="common-logo" />
          <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
          <div className="common-login-container">
            <div className="common-form-container">
              <Button className="loginButton-sendai" onClick={async () => await ProviderLoginButton('Cognito', setIsLoading)}>
                <img src={sendaiportalImage} alt="Sendai"/>
                SENDAIポータルでログイン
              </Button>
              <div className='account-interrupt'>
                <Link className='account-link' to="/resendcode">途中まで進めた登録を再開する</Link>
              </div>
              <div className="separate-login">
                <hr className="driver"></hr>
                <div className='social'>または外部サイトのIDでログイン</div>
                <hr className="driver"></hr>
              </div>
              <Button className="loginButton-social loginButton-social-google" onClick={async () => await ProviderLoginButton('Google', setIsLoading)}>
                <img src={googleImage} alt="Sendai" className='google-image'/>
                Googleでログイン
              </Button>
              <Button className="loginButton-social" onClick={async () => await ProviderLoginButton('Yahoo', setIsLoading)}>
                <img src={yahooImage} alt="Sendai" className='yahoo-image'/>
                Yahooでログイン
              </Button>
              <Button className="loginButton-social" onClick={async () => await ProviderLoginButton('SignInWithApple', setIsLoading)}>
                <img src={appleImage} alt="Sendai" className='apple-image'/>
                Appleでログイン
              </Button>
            </div>
          </div>
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>ログイン中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
        <footer className='common-login-footer'>
          <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default LoginPage;