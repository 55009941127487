//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

//CSSのインポート
import './EditInfoPage.css';
import '../Common.css';

//マテリアルデザインのインポート
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

//componentのインポート
import { inputStyles } from '../../../componets/TextField.jsx'
import { GetProfile } from './controller/GetProfile.jsx';
import { updateProfile } from './controller/UpdateProfile.jsx';

//画像のインポート
import {isKatakana, isPhoneNumber} from '../../../utils/validation.js'
import backgroundImage from '../../../assets/backgroun-image.png'


function EditInfoPage(){
  
  const [userInfo, setUserInfo] = useState(null);
  const [family_name, setFamilyName] = useState('');
  const [given_name, setGivenName] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [familyNameError, setFamilyNameError] = useState(false);
  const [givenNameError, setGivenNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  const [errorMessageGetProfile, setErrorMessageGetProfile] = useState('');
  const [errorCheckGetProfile, setErrorCheckGetProfile] = useState(false);

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
    setTimeout(() => {
      setIsFooterVisible(false);
    }, 350);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  //戻るボタンを押されたらアカウント画面に戻るための設定
  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  //Hooks関数の呼び出し（プロフィール情報取得の処理部分）
  GetProfile(setIsLoading, setUserInfo, setErrorMessageGetProfile, setErrorCheckGetProfile);

  //UserInfoを変数に代入
  useEffect(() => {
    if (userInfo) {
      setFamilyName(userInfo.family_name);
      setGivenName(userInfo.given_name);
      setPhoneNumber(userInfo.customPhone || '');
    }
  }, [userInfo]);

  //入力チェックがOKの場合にのみボタン活性化チェック
  useEffect(() => {
    if(family_name.length === 0 || given_name.length === 0){
      setIsButtonDisabled(true);
    }else if(family_name.length > 0 || given_name.length > 0){
      if (familyNameError || givenNameError || phoneNumberError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [familyNameError, givenNameError, phoneNumberError, family_name, given_name, phone_number]);

  //入力されたフリガナ（姓）のチェック
  useEffect(() => {
    if(family_name.length > 0){
      setFamilyNameError(!isKatakana(family_name));
    }
  }, [family_name]);

  //入力されたフリガナ（名）のチェック
  useEffect(() => {
    if(given_name.length > 0){
      setGivenNameError(!isKatakana(given_name));
    }
  }, [given_name]);

  //入力された電話番号のチェック
  useEffect(() => {
    if(phone_number.length > 0){
      setPhoneNumberError(!isPhoneNumber(phone_number));
    }else if(phone_number.length === 0){
      setPhoneNumberError(false);
    }
  }, [phone_number]);

  //変更ボタンをクリックしたときに実行
  const changeUserInfoButtonClick = async () => {
    const sanitizedFamilyName = DOMPurify.sanitize(family_name);
    const sanitizedGivenName = DOMPurify.sanitize(given_name);
    const sanitizedPhoneNumber = DOMPurify.sanitize(phone_number);
    await updateProfile(sanitizedFamilyName, sanitizedGivenName, sanitizedPhoneNumber, setIsComplete, setErrorMessage, setErrorCheck, setIsLoading)
  };

  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
        {errorCheckGetProfile &&(
          <div className='editinfo-page'>
            <div className='common-list-container-text'>
              <label>{errorMessageGetProfile}</label>
            </div>
          </div>
        )}
        {userInfo && (
          <div className='editinfo-page'>
            <div className='common-list-container-title'>
              <label>ユーザ情報変更</label>
            </div>
            {!isComplete && (
              <div className='common-list-container-text'>
                <label>プロフィール情報を変更してください</label>
              </div>
            )}
            {isComplete && (
              <div className='common-list-container-text'>
                <label>ユーザ情報の変更が完了しました。</label>
              </div>
            )}
            {!isComplete && (
              <div className='input-container-editinfo'>
                {errorCheck && 
                  <div className='error-massage'>
                    {errorMessage}
                  </div>
                }
                <label htmlFor="email">
                    フリガナ（姓）<span className='editinfo-label-color-required'>必須</span>
                </label>
                <TextField
                  type="text"
                  id="family_name"
                  value={family_name}
                  onChange={(e) => setFamilyName(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="ヤマダ"
                  fullWidth
                  error={familyNameError}
                  slotProps={{
                    input: {
                      sx: inputStyles
                    },
                  }}
                />
                {familyNameError && (
                  <FormHelperText error className='form-helper-tex'>
                    カタカナを入力してください（30文字以内）
                  </FormHelperText>
                )}
                <label htmlFor="email">
                    フリガナ（名）<span className='editinfo-label-color-required'>必須</span>
                </label>
                <TextField
                  type="text"
                  id="given_name"
                  value={given_name}
                  onChange={(e) => setGivenName(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="タロウ"
                  fullWidth
                  error={givenNameError}
                  slotProps={{
                    input: {
                      sx: inputStyles
                    },
                  }}
                />
                {givenNameError && (
                  <FormHelperText error className='form-helper-tex'>
                    カタカナを入力してください（30文字以内）
                  </FormHelperText>
                )}
                <label htmlFor="email">
                    電話番号<span className='editinfo-label-color-option'>　任意</span>
                </label>
                <TextField
                  type="tel"
                  id="phone_number"
                  value={phone_number}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  placeholder="xxx-xxxx-xxxx"
                  fullWidth
                  error={phoneNumberError}
                  slotProps={{
                    input: {
                      sx: inputStyles
                    },
                  }}
                  className={phoneNumberError ? '' : 'textfield-margin-bottom'} 
                />
                {phoneNumberError && (
                  <FormHelperText error className='form-helper-text-phone'>
                    数字のみで正しい電話番号を入力してください
                  </FormHelperText>
                )}
              </div>
            )}
            {!isComplete && (
              <Button className="editinfo-change-button" onClick={changeUserInfoButtonClick} disabled={isButtonDisabled}>変更する</Button>
            )}
            {!isComplete && (
              <Button className="editinfo-back-button" onClick={navigateToAccount}>戻る</Button>
            )}
            {isComplete && (
              <Button className="editinfo-back-button-iscomlete" onClick={navigateToAccount}>戻る</Button>
            )}
            {isLoading && (
              <div className="common-loading-overlay">
                <h2>ユーザー情報を登録中です。しばらくお待ちください。</h2>
                <div className="common-loader"></div>
              </div>
            )}
            {isFooterVisible && (
              <footer className='common-footer'>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
              </footer> 
            )}
          </div>
        )}
    </body>
  );
};

export default EditInfoPage;