import { useEffect } from 'react';
import { ReturnMail } from '../../../../service/ReturnMail';

export const GetMailAddress = (setIsLoading, setUserInfo) => {
    useEffect(() => {
        const fetchUserInfo = async () => {
          try {
            // const response = await fetch(`${process.env.REACT_APP_API_URL}/returnUserInfo/email`, {
            //   method: 'POST',
            //   headers: { 
            //     'Content-Type': 'application/json; charset=utf-8',
            //   },
            //   body: JSON.stringify({
            //     pattern:'user_info_email'
            //   }),
            //   credentials: 'include'
            // });
            const response = await ReturnMail();
            const data = await response.json();
            setUserInfo(data.Response);
          } catch (error) {
          } finally {
            setIsLoading(false);
          }
        };
    
        fetchUserInfo();
      }, []);
}