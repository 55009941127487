import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// import { Routes, Route } from 'react-router-dom';
// import { BrowserRouter } from 'react-router-dom';
import LoginPage from './views/LoginPage/LoginPageTop/LoginPage.jsx';
import ResendCodePage from './views/LoginPage/ResendCodePage/ResendCodePage.jsx';
import ConfirmPage from './views/LoginPage/CodeConfirmPage/ConfirmPage.jsx';
import ResendCodeConfirmPage from './views/LoginPage/CodeConfirmPage/ResendCodeConfirmPage.jsx';
import CompletePage from './views/LoginPage/CompletePage/CompletePage.jsx';
import AccountTopPage from './views/AccountPage/AccountTopPage/AccountTopPage.jsx';
import OptoinPage from './views/LoginPage/OptinPage/OptoinPage.jsx';
import ErrorPage from './ErrorPage.jsx';
import NickNamePage from './views/AccountPage/NickNamePage/NickNamePage.jsx';
import MailEditPage from './views/AccountPage/MailEditPage/MailEditPage.jsx';
import MailConfirmPage from './views/AccountPage/MailEditConfimPage/MailEditConfirmPage.jsx';
import PasswordEditPage from './views/AccountPage/PasswordEditPage/PasswordEditPage.jsx';
import EditInfoPage from './views/AccountPage/EditInfoPage/EditInfoPage.jsx';
import DeletePage from './views/AccountPage/DeletePage/DeletePage.jsx';
import RegistInfoPage from './views/LoginPage/RegistInfoPage/RegistInfoPage.jsx';
import XIDAliginmentPage from './views/AccountPage/XIDAlignmentPage/XIDAliginmentPage.jsx';
import ImageEditPage from './views/AccountPage/ImageEditPage/ImageEditPage.jsx';
import PermissionServicePage from './views/AccountPage/PermissionServicePage/PermissionServicePage.jsx';
import XIDReLogin from './xIDReLogin.jsx';

import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './hooks/AuthContext.js';

// const router = createBrowserRouter([
//   { path: "/", element: <ErrorPage /> },
//   { path: "/oauth2/authorize", element: <LoginPage /> },
//   { path: "/resendcode", element: <ResendCodePage /> },
//   { path: "/error", element: <ErrorPage /> },
//   { path: "/regist", element: <RegistInfoPage /> },
//   { path: "/confirm", element: <ConfirmPage /> },
//   { path: "/resendconfirm", element: <ResendCodeConfirmPage /> },
//   { path: "/complete", element: <CompletePage /> },
//   { path: "/account", element: <AccountTopPage /> },
//   { path: "/optin", element: <OptoinPage /> },
//   { path: "/editnickname", element: <NickNamePage /> },
//   { path: "/editmail", element: <MailEditPage /> },
//   { path: "/editmailconfirm", element: <MailConfirmPage /> },
//   { path: "/editpassword", element: <PasswordEditPage /> },
//   { path: "/editinfo", element: <EditInfoPage /> },
//   { path: "/xidalignment", element: <XIDAliginmentPage /> },
//   { path: "/xid_relogin", element: <XIDReLogin /> },
//   { path: "/editimage", element: <ImageEditPage /> },
//   { path: "/permission_service", element: <PermissionServicePage /> },
//   { path: "/delete", element: <DeletePage /> },
//   { path: "*", element: <ErrorPage /> },
// ]);

// function AppRoutes() {
//   return (
//     <RouterProvider
//       router={router}
//       future={{
//         v7_startTransition: true,
//         v7_relativeSplatPath: true,
//       }}
//     />
//   );
// }

// export default AppRoutes;


const router = createBrowserRouter([
  { path: "/", element: <ErrorPage /> },
  { path: "/oauth2/authorize", element: <LoginPage /> },
  { path: "/resendcode", element: <ResendCodePage /> },
  { path: "/error", element: <ErrorPage /> },
  { path: "/regist", element: <RegistInfoPage /> },
  { path: "/confirm", element: <ConfirmPage /> },
  { path: "/resendconfirm", element: <ResendCodeConfirmPage /> },
  { path: "/complete", element: <CompletePage /> },
  { path: "/account", element: <AccountTopPage /> },
  { path: "/optin", element: <PrivateRoute element={<OptoinPage />} /> },
  { path: "/editnickname", element: <PrivateRoute element={<NickNamePage />} /> },
  { path: "/editmail", element: <PrivateRoute element={<MailEditPage />} /> },
  { path: "/editmailconfirm", element: <PrivateRoute element={<MailConfirmPage />} /> },
  { path: "/editpassword", element: <PrivateRoute element={<PasswordEditPage />} /> },
  { path: "/editinfo", element: <PrivateRoute element={<EditInfoPage />} /> },
  { path: "/xidalignment", element: <PrivateRoute element={<XIDAliginmentPage />} /> },
  { path: "/xid_relogin", element: <PrivateRoute element={<XIDReLogin />} /> },
  { path: "/editimage", element: <PrivateRoute element={<ImageEditPage />} /> },
  { path: "/permission_service", element: <PrivateRoute element={<PermissionServicePage />} /> },
  { path: "/delete", element: <PrivateRoute element={<DeletePage />} /> },
  { path: "*", element: <ErrorPage /> },
]);

function AppRoutes() {
  return (
    <AuthProvider>
      <RouterProvider
        router={router}
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      />
    </AuthProvider>
  );
}

export default AppRoutes;