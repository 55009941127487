//React標準パッケージのインポート
import React from 'react';
import { useNavigate } from 'react-router-dom';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//コンポーネントのインポート
import ProgressSteps from '../../../componets/ProgressSteps.jsx';

//CSSのインポート
import '../LoginCommon.css';
import './CompletePage.css'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png';
import sendaiImage from '../../../assets/sendai.png';

function CompletePage(){
  const navigate = useNavigate();
  const navigateToSignUpScreen = () => {
    navigate('/oauth2/authorize');
  };

  return (
    <div className="common-page">
      <img src={backgroundImage} alt="Background" className="common-background-image" />
      <img src={sendaiImage} alt="Sendai" className="common-logo" />
        <h2>SENDAIポータル<br></br>ログイン／新規登録</h2>
        <ProgressSteps activeStep={3}/>
      <div className="common-login-container">
        <div className="common-form-container">
        <div className='complete-account-message-box'>
            <label className='complete-account-message-box-label'>アカウント登録が完了しました</label>
            <br/>
            <label className='complete-account-message-box-label2'>「ログイン」ボタンをクリックして<br/>サービスをご利用ください</label>
        </div>
        <Button className="common-button" onClick={navigateToSignUpScreen}>ログイン</Button>
        </div>
      </div>
      <footer className='common-login-footer'>
        <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default CompletePage;