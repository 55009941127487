//ユーザ情報登録
export const userInfoRegist = async (family_name, given_name, nickname, phone_number, iconImage) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/registUserInfo`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              pattern:'regist_info_first',
              userAttributes: [
                { Name: "family_name", Value: family_name},
                { Name: "given_name", Value: given_name},
                { Name: "nickname", Value: nickname},
                { Name: "custom:phone", Value: phone_number},
                { Name: "picture", Value: iconImage},
                { Name: "custom:optin", Value: '1'}
              ]
            }),
            credentials: 'include'
        });
        //リクエスト元に情報を返す
        return response;
    } catch (error) {
      return error;
    }
  };