import { UpdatePermission } from "../../../../service/UpdatePermission";

export const updatePermission = async (setIsLoading, setIsComplete ,newOptinPermission, setErrorMessage, setErrorCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/service`, {
        //   method: 'POST',
        //   headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        //   },
        //   body: JSON.stringify({
        //     userAttributes: [
        //       ...Object.keys(newOptinPermission).map(key => ({
        //         Name: key,
        //         Value: newOptinPermission[key]
        //       }))
        //     ],
        //   }),
        //   credentials: 'include'
        // });


        const response = await UpdatePermission(newOptinPermission);
  
        if (response.ok) {
          setIsComplete(true);
        } else {
          setErrorMessage('連携サービスの更新に失敗しました。');
          setErrorCheck(true);
        }
  
      } catch (error) {
        setErrorMessage('連携サービスの更新に失敗しました。');
        setErrorCheck(true);
      } finally{
        setIsLoading(false);
      }
    }, 50);
}