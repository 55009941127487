import React from "react";
import PropTypes from "prop-types";
import { Stack, Stepper, Step, StepLabel } from "@mui/material";
import Check from "@mui/icons-material/Check";
import StepConnector from "@mui/material/StepConnector";
import "./ProgressSteps.css"; // CSSファイルをインポート

function ColorlibStepIcon({ active, completed }) {
  return (
    <div
      className={`colorlib-step-icon-root ${active ? "active" : ""} ${
        completed ? "completed" : ""
      }`}
    >
      {completed ? (
        <>
          <div className="check-filled-circle" />
          <Check className="check-mark" />
        </>
      ) : active ? (
        <>
          <div className="active-filled-circle" />
          <div className="active-mark" />
        </>
      ) : (
        <div className="white-filled-circle" />
      )}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const steps = [
  <span >STEP1<br />アドレス入力</span>,
  <span >STEP2<br />確認コード発行</span>,
  <span >STEP3<br />本登録完了</span>,
];

export default function ProgressSteps({ activeStep }) {
  return (
    <div className="progress-steps-container">
      <Stack>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<StepConnector className="colorlib-connector" />}
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Stack>
    </div>
  );
}

ProgressSteps.propTypes = {
  activeStep: PropTypes.number.isRequired,
};
