// userLogin.js
import { useEffect } from 'react';
import { ReturnProfile } from '../../../../service/ReturnProfile';

export const GetProfile = (setIsLoading, setUserInfo, setErrorMessageGetProfile, setErrorCheckGetProfile) => {
    useEffect(() => {
        const getProfile = async () => {
          try {
            //const response = await fetch(`${process.env.REACT_APP_API_URL}/returnUserInfo/profile`, {
            //   method: 'POST',
            //   headers: { 
            //     'Content-Type': 'application/json; charset=utf-8',
            //   },
            //   body: JSON.stringify({
            //     pattern:'user_info_profile'
            //   }),
            //   credentials: 'include'
            // });
            const response = await ReturnProfile();

            if(response.ok){
                const data = await response.json();
                setUserInfo(data.Response);
            }else{
                const errorData = await response.json();
                setErrorMessageGetProfile(errorData.errors.message);
                setErrorCheckGetProfile(true);
            }
          } catch (error) {
            console.error('Error fetching user info:', error);
            setErrorMessageGetProfile(error.message);
            setErrorCheckGetProfile(true);
          } finally {
            setIsLoading(false);
          }
        };
    
        getProfile();
    }, []);
};