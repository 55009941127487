//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//CSSのインポート
import './PasswordEditPage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

//componentのインポート
import { inputStyles } from '../../../componets/TextField.jsx'
import { passwordChange } from './controller/PasswordChangeButton.jsx';
import {isValidPassword, isConfirmPassword} from '../../../utils/validation.js'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'


function PasswordEditPage(){
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordCheck, setNewPasswordCheck] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCheck, setErrorCheck] = useState(false);

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
    setTimeout(() => {
      setIsFooterVisible(false);
    }, 350);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  //変更ボタンをクリックしたときに実行
  const changePasswordButtonClick = async () => {
    await passwordChange(setIsLoading, password, newPassword, setIsComplete, setErrorMessage, setErrorCheck);
  };

  useEffect(() => {
    if(password.length === 0 || newPassword.length === 0 || newPasswordCheck.length === 0){
      setIsButtonDisabled(true);
    }else if(password.length > 0 || newPassword.length > 0 || newPasswordCheck.length > 0){
      if (passwordError || passwordCheckError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [passwordError, passwordCheckError, password, newPassword,  newPasswordCheck]);

  //入力されたパスワードのチェック
  useEffect(() => {
    if(newPassword.length > 0){
      setPasswordError(!isValidPassword(newPassword));
    }
  }, [newPassword]);
  //入力されたパスワード確認の情報のチェック
  useEffect(() => {
    if(newPasswordCheck.length > 0){
      setPasswordCheckError(!isConfirmPassword(newPasswordCheck, newPassword));
    }
  }, [newPasswordCheck, newPassword]);
  
    return (
        <body>
          <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
          <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
          <div className='editpassword-page'>
            <div className='common-list-container-title'>
              <label>パスワード変更</label>
            </div>
            {!isComplete && (
              <div className='common-list-container-text'>
                <label>変更するパスワードを入力してください</label>
              </div>
            )}
            {isComplete && (
              <div className='common-list-container-text'>
                <label>パスワードの変更が完了しました。</label>
              </div>
            )}
            {!isComplete && (
              <div className='input-container-password'>
                {errorCheck && 
                  <div className='error-massage'>
                    {errorMessage}
                  </div>
                }
                <label htmlFor="password">現在のパスワード</label>
                <TextField
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    fullWidth
                    slotProps={{
                    input: {
                      endAdornment: null,
                      sx: inputStyles
                    },
                    }}
                />
                <label htmlFor="password">新しいパスワード</label>
                <TextField
                    type="password"
                    id="newPassword"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    fullWidth
                    error={passwordError}
                    slotProps={{
                    input: {
                        sx: inputStyles
                    },
                    }}
                />
                {passwordError && (
                    <FormHelperText error className='form-helper-text-password'>
                        10文字以上で、大文字、小文字、数字、記号
                    </FormHelperText>
                )}
                <label htmlFor="passwordCheck">新しいパスワード再入力（確認用）</label>
                <TextField
                    type="password"
                    id="passwordCheck"
                    value={newPasswordCheck}
                    onChange={(e) => setNewPasswordCheck(e.target.value)}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    fullWidth
                    error={passwordCheckError}
                    helperText={passwordCheckError ? 'パスワードが一致しません。' : ''}
                    slotProps={{
                    input: {
                        sx: inputStyles
                    },
                    }}
                    className='textfield-margin-bottom'
                />
              </div>
            )}
            {!isComplete && (
              <Button className="editpassword-change-button" onClick={changePasswordButtonClick} disabled={isButtonDisabled}>変更する</Button>
            )}
            {!isComplete && (
              <Button className="editpassword-back-button" onClick={navigateToAccount}>戻る</Button>
            )}
            {isComplete && (
              <Button className="editpassword-back-button-iscomplete" onClick={navigateToAccount}>戻る</Button>
            )}
            {isLoading && (
              <div className="common-loading-overlay">
                <h2>パスワードを変更中です。しばらくお待ちください。</h2>
                <div className="common-loader"></div>
              </div>
            )}
            {isFooterVisible && (
              <footer className='common-footer'>
                <p>Copyright©City of Sendai All Rights Reserved.</p>
              </footer> 
            )}
          </div>
        </body>
      );
};

export default PasswordEditPage;