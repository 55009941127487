// userLogin.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkOptoin } from '../../../../service/OptinService.js';
import { requestLogin } from '../../../../service/RequestLogin.js';
import { signinForAccount } from '../../../../service/SocialLoginService.js';


export const CodeProcess = (location, setIsLoading) => {
  const navigate = useNavigate();
  const keysToCheck = ['client_id', 'redirect_uri', 'scope', 'response_type'];

  useEffect(() => {
    //関数の定義
    const fetchToken = async () => {
        //URLを取得するための初期化
        const params = new URLSearchParams(location.search);
        //認可code取得
        const codeParam = params.get('code');
        //stateを取得
        const stateParam = params.get('state');
        //セッションからstateを取得
        const sessionState = sessionStorage.getItem('state');
        // チェックするクエリパラメータが含まれているか確認（'client_id', 'redirect_uri', 'scope', 'response_type'）
        const checkQuery = keysToCheck.some(key => params.has(key));

        //認可コードが存在する場合は以下を実行
        if (codeParam && (sessionState === stateParam)) {
            //ログイン中の画面を表示
            setIsLoading(true);
            //エラーの可能性もある処理（API利用）を実行
            try {
                //オプトインチェック（API通信発生）
                const response = await checkOptoin(codeParam);
                //API取得成功し場合に以下を実行
                if (response.ok) {
                    //レスポンスデータをjson形式にする
                    const responseData = await response.json();
                    //オプトインを許可していない場合は以下を実行
                    if (responseData.customOptin === 'null' || responseData.customOptin === '0') {
                        navigate('/optin' , { state: { 
                            userInfo_flag: responseData.infoFlag, 
                            nickname:responseData.nickname, 
                            optin:responseData.customOptin, 
                            optin_tag_name:responseData.optin_tag_name,
                            image_url: responseData.image_url, 
                            service_name: responseData.service_name
                        }});
                    } 
                    //オプトインを許可している場合は以下を実行
                    else {
                        //ユーザ情報を登録済みのユーザは以下を実行
                        if (responseData.infoFlag) {
                            //再度ログインをして、接続元に認可コードを返す
                            requestLogin();
                        } 
                        //ユーザ情報を未登録のユーザは以下を実行
                        else if (!responseData.infoFlag) {
                            //ユーザ登録画面に遷移
                            navigate('/regist', { state: { nickname: responseData.nickname } });
                        }
                    }
                } 
                //API取得に失敗し場合に以下を実行
                else {
                    //ログイン中の画面を表示を終わりにする
                    setIsLoading(false);
                }
            } 
            //上記try内でエラーが起こった場合には以下を実行
            catch (error) {
                //ログイン中の画面を表示を終わりにする
                setIsLoading(false);
            }
        }
        //最初のアクセス時にクエリパラメータをセッションストレージに保存
        else if (checkQuery) {
            //クエリパラメータをStringで取得
            const queryString = params.toString();
            //セッションストレージに上記で取得したStringを保存
            sessionStorage.setItem('queryString', queryString);
            //クエリパラメータからclient_idのみを取得
            const client_id = params.get('client_id');
            //クエリに言語情報が設定されていた場合に以下を実行
            if (client_id) {
                //セッションストレージにclient_idを保存
                sessionStorage.setItem('client_id', client_id);
            }
            //クエリパラメータからlanguageのみを取得
            const language = params.get('language');
            //クエリに言語情報が設定されていた場合に以下を実行
            if (language) {
                //セッションストレージに言語情報を保存
                sessionStorage.setItem('lang', language);
            }
        }else{
            const check = sessionStorage.getItem('queryString');
            if(!check){
                signinForAccount();
            }
        }
    };
    //上記関数を実行（非同期で実行するためにこのようなやりかたとしている）
    fetchToken();
  }, [location, setIsLoading, navigate]);
};