import { UpdateIcon } from "../../../../service/UpdateIcon";

export const iconEdit = async (setIsLoading, defaultFlag, defaultImage, base64Image, setIsButtonDisabled, setErrorMessage, setErrorCheck, setSuccessCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
      console.log(`defaultFlag:${defaultFlag}`);
      try {
        //画像を保存
        const response = await UpdateIcon(defaultFlag, defaultImage, base64Image);
        if (response.ok) {
          setIsButtonDisabled(true);
          setErrorCheck(false);
          setSuccessCheck(true);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }
  
      } catch (error) {
        setErrorCheck('アイコンの更新に失敗しました。');
        setErrorCheck(true);
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }