//Cognito言語設定
export const setLang = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/lang`, {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
                pattern:'langCookie'
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};