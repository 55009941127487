import { UpdateProfile } from "../../../../service/UpdateProfile";

export const updateProfile = async (family_name, given_name, phone_number, setIsComplete, setErrorMessage, setErrorCheck, setIsLoading) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/registUserInfo`, {
        //   method: 'POST',
        //   headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        //   },
        //   body: JSON.stringify({
        //     userAttributes: [
        //       { Name: "family_name", Value: family_name},
        //       { Name: "given_name", Value: given_name},
        //       { Name: "custom:phone", Value: phone_number}
        //     ],
        //     pattern:'regist_info'
        //   }),
        //   credentials: 'include'
        // });

        const response = await UpdateProfile(family_name, given_name, phone_number);
  
        if (response.ok) {
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }
  
      } catch (error) {
        //
      } finally{
        setIsLoading(false);
      }
    }, 50);
}