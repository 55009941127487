import { RegistNickName } from "../../../../service/RegistNickName";

export const nickNameChange = async (setIsLoading, newNickName, setIsComplete, setErrorMessage, setErrorCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/NickName`, {
        //   method: 'POST',
        //   headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        //   },
        //   body: JSON.stringify({
        //     userAttributes: [
        //       { Name: "nickname", Value: newNickName},
        //     ],
        //     pattern:'regist_info'
        //   }),
        //   credentials: 'include'
        // });

        const response = await RegistNickName(newNickName);
  
        if (response.ok) {
          // セッションストレージにニックネームを保存
          sessionStorage.setItem('nickname', newNickName);
          setIsComplete(true);
        } else {
          setErrorMessage('ニックネームの変更に失敗しました。');
          setErrorCheck(true);
        }
  
      } catch (error) {
        setErrorMessage('ニックネームの変更に失敗しました。');
        setErrorCheck(true);
      } finally{
        setIsLoading(false);
      }
    }, 50);
}