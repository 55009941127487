//Reactのインポート
import React, { useState } from 'react';

//CSSのインポート
import './DeletePage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//componentのインポート
import { UserDelete } from './controller/UserDelete.jsx';


export default function DeleteConfirmPage({onBackButtonClick, errorTextDisplay, successDelete}){
  const [isLoading, setIsLoading] = useState(false);
  
  
  return (
      <div className='delete-page'>
        <div className='confirm-container-delete'>
          <p className='confirm-container-delete-label' style={{marginTop:'25px'}}>一度アカウントを削除すると、アカウント情報を元に戻すことはできません。</p>
          <p className='confirm-container-delete-label' style={{marginBottom:'35px'}}>本当に削除してもよろしいですか？</p>
          <Button className="change" onClick={async () => await UserDelete(successDelete, setIsLoading, errorTextDisplay, onBackButtonClick)}>削除する</Button>
          <Button className="back" onClick={onBackButtonClick} style={{marginBottom: '35px'}}>戻る</Button>
        </div>
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>アカウント情報を削除中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
      </div>  
  );
};