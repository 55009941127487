import React from 'react';
import { Link } from 'react-router-dom';
import './ErrorPage.css'; // スタイリング用のCSSファイルをインポート

const ErrorPage = () => {
  return (
    <div className="not-found">
      <h1>404</h1>
      <p>お探しのページは見つかりませんでした。</p>
      <Link to="/oauth2/authorize">ホーム画面に戻る</Link>
    </div>
  );
};

export default ErrorPage;