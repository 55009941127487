import { resedCodePublish } from '../../../../service/RecendCode.js';

export const ResendCodeButton = async (email,setIsLoading, setErrorMessage, setErrorCheck, navigate) => {
    if (email) {
        setIsLoading(true);
        try {
            const response = await resedCodePublish(email);
            if (response.ok) {
                navigate('/confirm' , { state: { email: email } } )
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.message);
                setErrorCheck(true);
            }
        } catch (error) {
            setErrorMessage('検証コードの再発行に失敗しました');
            setErrorCheck(true);
        } finally {
            setIsLoading(false);
        }
    } else {
        const translatedMessage = 'メールアドレスが入力されていません';
        setErrorMessage(translatedMessage);
        setErrorCheck(true);
    }
};