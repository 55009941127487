import { DeletexID } from "../../../../service/DeletexID";

export const xIDDelete = async (setIsLoading, successXIDCancel, errorTextDisplay) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/xIDdelete`, {
        //   method: 'POST',
        //   headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        //   },
        //   body: JSON.stringify({
        //     pattern:'xiddelete'
        //   }),
        //   credentials: 'include'
        // });
        const response = await DeletexID();

        if (response.ok) {
          sessionStorage.setItem('x_flag', '0');
          successXIDCancel();
        } else {
          errorTextDisplay('マイナンバー連携の解除に失敗しました。');
        }
  
      } catch (error) {
        errorTextDisplay('マイナンバー連携の解除に失敗しました。');
      } finally{
        setIsLoading(false);
      }
    }, 50);
  }