//Reactのインポート
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//CSSのインポート
import './ImageEditPage.css';
import '../Common.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';

//componentのインポート
import { iconEdit } from './controller/EditIcon.jsx';
import { handleFileInput } from './controller/ClipImage.jsx';

//画像のインポート
import Shirokumasan from '../../../assets/shirokuma.png';
import Kumasan from '../../../assets/kuma.png';
import Kitsunesan from '../../../assets/kitsune.png';
import Osarusan from '../../../assets/saru.png';
import backgroundImage from '../../../assets/backgroun-image.png'


function ImageEditPage(){
  const [selectedImage, setSelectedImage] = useState(sessionStorage.getItem('picture'));
  const [base64Image, setBase64Image] = useState('');
  const [defaultImage, setDefaultImage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFlag, setDefaultFlag] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('画像の更新に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const successMessage = '画像の更新が完了しました。';
  const [successCheck, setSuccessCheck] = useState(false);
  const images = {
    [Shirokumasan]:`${process.env.REACT_APP_S3_DOMAIN}'/user_icons/shirokuma.png`,
    [Kumasan]:`${process.env.REACT_APP_S3_DOMAIN}/user_icons/kuma.png`,
    [Kitsunesan]:`${process.env.REACT_APP_S3_DOMAIN}/user_icons/kitsune.png`,
    [Osarusan]:`${process.env.REACT_APP_S3_DOMAIN}/user_icons/saru.png`
  };
  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  //変更ボタンをクリックしたときに実行
  const changeIconClick = async () => {
    await iconEdit(setIsLoading, defaultFlag, defaultImage, base64Image, setIsButtonDisabled, setErrorMessage, setErrorCheck, setSuccessCheck);
  };

  //デフォルトのアイコンをクリックしたときに実行
  const handleClick = (image) => {
    //変更ボタンを押せるようにする
    setIsButtonDisabled(false);
    //デフォルト画像が選択されたフラグをtrueにする
    setDefaultFlag(true);
    //選択した画像を入れる変数にデフォルト画像を入れる（例：image="Shirokumasan"）
    setSelectedImage(image);
    //Lambdaに送る画像を入れる変数にデフォルト画像のURLを入れる
    setDefaultImage(images[image]);
  };

  //アップロードボタンをクリックしたときに実行
  const uploadClick = async (event) => {
    await handleFileInput(event, setIsButtonDisabled, setDefaultFlag, setSelectedImage, setBase64Image);
    console.log(`selectImage:${selectedImage}`);
  };
  
  return (
    <div>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
        <div className='editimage-page'>
          <div className='common-list-container-title common-list-container-title-custom'>
            <label>アイコン変更</label>
          </div>
          {successCheck && 
            <div className='success-massage'>
              {successMessage}
            </div>
          }
          {errorCheck && 
            <div className='error-massage'>
              {errorMessage}
            </div>
          }
          <label className='current-icon-title'>
            現在のアイコン
          </label>
          <div className="current-icon-display">
            <img src={selectedImage} alt="single-icon" className="icon-image" />
          </div>
          <label className='select-icon-title'>
            プリセット／ライブラリから画像を選択できます。
          </label>
          <div className="select-image-container">
            <div className="select-image-scroll-container">
              {Object.keys(images).map((image, index) => (
                <div key={index} className="select-icon-display" onClick={() => handleClick(image)}>
                  <img src={image} alt={`icon-${index}`} className="icon-image" />
                </div>
              ))}
              <div className="select-icon-display">
                <input
                  type="file"
                  accept="image/*"
                  id="file"
                  className="icon-image-input"
                  onChange={uploadClick}
                />
                <label htmlFor="file" className="file-label">
                  <IconButton component="span" className="file-button">
                    <AddIcon className="plus-icon"/>
                  </IconButton>
                </label>
              </div>
            </div>
          </div>
          <Button className="editimage-change-button" onClick={changeIconClick} disabled={isButtonDisabled}>変更する</Button>
          <Button className="editimage-back-button" onClick={navigateToAccount}>戻る</Button>
          {isLoading && (
            <div className="common-loading-overlay">
              <h2>アイコン画像を変更中です。しばらくお待ちください。</h2>
              <div className="common-loader"></div>
            </div>
          )}
          <footer className='common-footer'>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer> 
        </div>
    </div>
  );
};

export default ImageEditPage;