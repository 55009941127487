import { EditPassword } from "../../../../service/EditPassword";

export const passwordChange = async (setIsLoading, password, newPassword, setIsComplete, setErrorMessage, setErrorCheck) => {
    setIsLoading(true);
    setTimeout( async () => {
      try {
        // const response = await fetch(`${process.env.REACT_APP_API_URL}/editpassword/editpassword`, {
        //   method: 'POST',
        //   headers: { 
        //     'Content-Type': 'application/json; charset=utf-8',
        //   },
        //   body: JSON.stringify({
        //     password: password,
        //     new_password: newPassword,
        //     pattern:'edit_password'
        //   }),
        //   credentials: 'include'
        // });

        const response = await EditPassword(password, newPassword);
  
        if (response.ok) {
          setIsComplete(true);
        } else {
          const errorData = await response.json();
          setErrorMessage(errorData.errors.message);
          setErrorCheck(true);
        }
  
      } catch (error) {
        setErrorMessage('パスワードの変更に失敗しました。');
        setErrorCheck(true);
      } finally{
        setIsLoading(false);
      }
    }, 50);
}