//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

//CSSのインポート
import './MailEditConfirmPage.css';

//マテリアルデザインのインポート
import { TextField } from '@mui/material';
import { Button } from '@mui/material';

//componentのインポート
import { inputStyles, inputStylesMail } from '../../../componets/TextField.jsx'
import { mailEditConfirm } from './controller/MailConfirmButton.jsx';
import {isCode} from '../../../utils/validation.js'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'


function MailConfirmPage(){
  const location = useLocation();
  const { email, user_id } = location.state || {};
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState('メールアドレスの変更に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  //入力されたコードのチェック
  useEffect(() => {
    if (codeError) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [codeError,code]);

  //入力されたコードのチェック
  useEffect(() => {
    if(code.length > 0){
      setCodeError(!isCode(code));
    }
  }, [code]);

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
  };

  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  //変更ボタンをクリックしたときに実行
  const changeMailButtonClick = async () => {
    //サニタイズ処理
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedUserID = DOMPurify.sanitize(user_id);
    const sanitizedCode = DOMPurify.sanitize(code);
    //処理関数呼び出し
    await mailEditConfirm(setIsLoading, setTimeout, sanitizedEmail, sanitizedUserID, sanitizedCode, setIsComplete, setErrorMessage, setErrorCheck);
  };

  return (
    <div>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
      <div className='maileditconfirm-page'>
        <div className='common-list-container-title'>
          <label>メールアドレス変更</label>
        </div>
        {!isComplete && (
          <div className='common-list-container-text'>
            <label>変更したメールアドレスに送信された検証コードを入力してください。</label>
          </div>
        )}
        {isComplete && (
          <div className='common-list-container-text'>
            <label>メールアドレスの変更が完了しました。</label>
          </div>
        )}
        {!isComplete && (
          <div className='input-container-editmail'>
            {errorCheck && 
              <div className='error-massage'>
                {errorMessage}
              </div>
            }
            <label htmlFor="email">
              変更後のメールアドレス
            </label>
            <TextField
              type="email"
              id="email"
              value={email}
              onChange={() => {}}
              fullWidth
              disabled
              slotProps={{
                input: {
                  sx: inputStylesMail
                },
              }}
            />
            <label htmlFor="email">
              確認コード
            </label>
            <TextField
              type="text"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              helperText={codeError ? '無効なコードです' : ''}
              error={codeError}
              fullWidth
              onFocus={handleFocus}
              onBlur={handleBlur}
              slotProps={{
                input: {
                  sx: inputStyles
                },
              }}
              className='text-field'
            />
          </div>
        )}
        {!isComplete && (
          <Button className="maileditconfirm-change-button" onClick={changeMailButtonClick} disabled={isButtonDisabled}>確認する</Button>
        )}
        {!isComplete && (
          <Button className="maileditconfirm-back-button" onClick={navigateToAccount}>戻る</Button>
        )}
        {isComplete && (
          <Button className="maileditconfirm-back-button-iscomlete" onClick={navigateToAccount}>戻る</Button>
        )}
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>メールアドレスを変更中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
        {isFooterVisible && (
          <footer className='common-footer'>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer> 
        )}
      </div>
    </div>
  );
};

export default MailConfirmPage;