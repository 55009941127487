//ユーザ情報登録
export const DeleteUser = async () => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/delete`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              pattern:'delete_user'
            }),
            credentials: 'include'
        });
        //リクエスト元に情報を返す
        return response;
    } catch (error) {
      return error;
    }
  };