//Googleサインインメソッド呼び出し
import { setOptoin } from '../../../../service/OptinService.js';
//クライアントによるリクエストログイン（アプリに戻すためのログイン）
import { requestLogin } from '../../../../service/RequestLogin.js';

export const OptinButton = async (setIsLoading, userInfo_flag, nickname, navigate, optin_tag_name) => {
    setIsLoading(true);
    //Optinの更新とUserInfoチェック
    setTimeout( async () => {
      try{
        const response = await setOptoin(optin_tag_name);
        //更新が成功していたら以下処理を実行
        if (response.ok) {
          const responseData = await response.json();
          if(userInfo_flag){
            requestLogin();
          }else if(!userInfo_flag){
            navigate('/regist', { replace: true, state: { nickname:nickname } } );
          }
        } else {
          const errorData = await response.json();
          setIsLoading(false);
        }
      }catch(error){
        setIsLoading(false);
      }
    }, 50);
};