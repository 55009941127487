import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './views/AccountPage/AccountTopPage/AccountPage.css';

function XIDReLoginPage(){

  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setSuccess] = useState(false);
  const location = useLocation();

  //画面作成時に実行される
  useEffect(() => {
    const fetchToken = async () => {
      //URLを取得するための初期化
      const params = new URLSearchParams(location.search);
      //認可code取得
      const codeParam = params.get('code');

      if (codeParam){
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/xIDtokenupdate`, {
              method: 'POST',
              headers: { 
                'Content-Type': 'application/json; charset=utf-8',
                'Authorization': `Bearer ${codeParam}`
              },
              body: JSON.stringify({
                redirect_uri: `${process.env.REACT_APP_PORTAL_DOMAIN}/xid_relogin`,
                pattern:'xid_relogin'
              }),
              credentials: 'include'
            });
          if (response.ok) {
            setSuccess(true);
          } else {
            setSuccess(false);
          }
        } catch (error) {
          setSuccess(false);
        } finally{
          params.delete('code');
          const newUrl = `${location.pathname}?${params.toString()}`;
          window.history.replaceState(null, '', newUrl);
          setIsLoading(false);
        }
      }
    };

    //userEfect内で非同期処理を実行
    fetchToken();

  }, [location]);
  
  return (
    <div className='account-page'>
      {(!isLoading && isSuccess) && (
        <div>
          <h2 style={{marginTop:'80px', marginLeft:'40px', marginRight:'40px'}}>
            右上の×ボタンからアプリに戻ってください
          </h2>
        </div>
      )}
      {(!isLoading && !isSuccess) && (
        <div>
          <h2 style={{marginTop:'80px', marginLeft:'40px', marginRight:'40px'}}>
              マイナンバー連携の更新に失敗しました。右上の×ボタンからアプリに戻ってください
          </h2>
        </div>
      )}
      {isLoading && (
        <div className="common-loading-overlay">
          <h2 style={{marginTop:'80px', marginLeft:'40px', marginRight:'40px'}}>マイナンバー連携の更新中です。しばらくお待ちください。</h2>
          <div className="common-loader"></div>
        </div>
      )}
      <footer>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
      </footer>
    </div>
  );
};

export default XIDReLoginPage;