//Reactのインポート
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

//CSSのインポート
import './DeletePage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//componentのインポート
import DeleteConfirmPage from './DeleteConfirmPage.jsx';

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'


function DeletePage(){
  const [isConfirm, setIsConfirm] = useState(false);
  const [errorMessage, setErrorMessage] = useState('アカウントの削除に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const [isDeleteCheck, setIsDeleteCheck] = useState(false);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };

  //確認画面で戻るボタンが押されたとき
  const handleBackButtonClick = () => {
    setIsConfirm(false)
  };
  //アカウント削除に失敗したとき
  const errorTextDisplay = (errorMessage) => {
    setErrorMessage(errorMessage);
    setErrorCheck(true)
  };

  //アカウント削除に失敗したとき
  const successDelete = () => {
    setIsConfirm(false)
    setIsDeleteCheck(true)
  };

  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
      <div className='delete-page'>
        <div className='common-list-container-title'>
          <label>アカウント削除</label>
        </div>
        {isDeleteCheck && (
          <div className='common-list-container-text'>
            <label>アカウントを削除しました。</label>
          </div>
        )}
        {!isDeleteCheck && (
          <div className='common-list-container-text common-list-container-text-custom'>
            {errorCheck && 
              <div className='error-massage'>
                {errorMessage}
              </div>
            }
            <label className='bold-title'>アカウントを削除します。</label>
            <p>アカウントを削除すると、マイページ内の情報（メールアドレス、ニックネーム、パスワード、連携データ）が完全に削除されます。</p>
            <p>「削除する」ボタンを押すと、操作を取り消すことはできません。</p>
            <label className='bold-title'>一時的に連携を解除するには</label>
            <p>アカウント情報変更画面の「ログアウト」を選択すると、一時的にマイページからアカウントを切り離すことができます。</p>
          </div>
        )}
        {!isDeleteCheck && (
          <Button className="delete-Button" onClick={() => setIsConfirm(true)}>アカウント削除</Button>
        )}
        {!isDeleteCheck && (
          <Button className="delete-backButton" onClick={navigateToAccount}>戻る</Button>
        )}
        <footer className='common-footer'>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
        </footer>
        {isConfirm && (
          <div className="delete-confirm-overlay">
            <DeleteConfirmPage 
              onBackButtonClick={handleBackButtonClick}
              errorTextDisplay={errorTextDisplay}
              successDelete={successDelete}
            />
          </div>
        )}
      </div>
    </body>
  );
};

export default DeletePage;