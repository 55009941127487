//Reactのインポート
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

//CSSのインポート
import './XIDAliginmentPage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';

//コンポーネントまたはサービスのインポート
import { XIDCodeProcess } from './controller/XIDSignIn.jsx';
import { xIDSignIn } from '../../../service/SocialLoginService';
import backgroundImage from '../../../assets/backgroun-image.png'
import XIDDeletePage from './XIDDeletePage.jsx';

function XIDAliginmentPage(){
  const navigate = useNavigate();
  const navigateToAccount = () => {
    navigate('/account');
  };
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('マイナンバーカード連携に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  //Hooks関数の呼び出し（認可コードの処理部分）
  XIDCodeProcess(location, setIsLoading, setErrorMessage, setErrorCheck);

  async function xIDAliginmentButton(){
    sessionStorage.setItem('provider', 'xID');
    xIDSignIn('xID');
  }

  //確認画面で戻るボタンが押されたとき
  const handleBackButtonClick = () => {
    setIsConfirm(false)
  };
  //xid解除に失敗したとき
  const errorTextDisplay = (errorMessage) => {
    setErrorMessage(errorMessage);
    setErrorCheck(true)
  };

  //アカウント削除に成功したとき
  const successXIDCancel = () => {
    setDeleteCheck(true)
  };


  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
        {deleteCheck && (
          <div className='xidalignment-page'>
            <div className='common-list-container-title'>
              <label>マイナンバーカード連携</label>
            </div>
            <div className='common-list-container-text common-list-container-text-custom'>
                <p>マイナンバーカード連携を解除しました。</p>
            </div>
            <Button className="xidalignment-back-button-unlink-complete" onClick={navigateToAccount}>戻る</Button>
          </div>
        )}
        {!deleteCheck && (
          <div className='xidalignment-page'>
            <div className='common-list-container-title'>
              <label>マイナンバーカード連携</label>
            </div>
            {sessionStorage.getItem('x_flag') === '1' && (
              <div className='common-list-container-text common-list-container-text-custom'>
                {errorCheck && 
                  <div className='error-massage'>
                    {errorMessage}
                  </div>
                }
                <p>マイナンバーカードは連携済みです。</p>
              </div>
            )}
            {sessionStorage.getItem('x_flag') === '0' && (
              <div className='common-list-container-text common-list-container-text-custom2'>
                {errorCheck && 
                  <div className='error-massage'>
                    {errorMessage}
                  </div>
                }
                <label style={{fontSize: '16px', fontWeight: '700', marginTop:'10px', marginBottom:'20px', display: 'block'}}>マイナンバーカード連携について</label>
                <p>個人情報を入力する必要がなくなり～。</p>
                <label style={{fontSize: '16px', fontWeight: '400', marginTop:'30px', marginBottom:'150px', display: 'block'}}>一時的に連携を解除するには</label>
              </div>
            )}
            {sessionStorage.getItem('x_flag') === '0' && (
              <Button className="xidalignment-button" onClick={xIDAliginmentButton}>連携する</Button>
            )}
            {sessionStorage.getItem('x_flag') === '0' && (
              <Button className="xidalignment-back-button" onClick={navigateToAccount}>戻る</Button>
            )}
            {sessionStorage.getItem('x_flag') === '1' && (
              <Button className="xidalignment-delete-button" onClick={() => setIsConfirm(true)}>解除する</Button>
            )}
            {sessionStorage.getItem('x_flag') === '1' && (
              <Button className="xidalignment-back-button-iscomplete" onClick={navigateToAccount}>戻る</Button>
            )}
            {isLoading && (
              <div className="common-loading-overlay">
                <h2>xID連携中です。しばらくお待ちください。</h2>
                <div className="common-loader"></div>
              </div>
            )}
            <footer className='common-footer'>
                  <p>Copyright©City of Sendai All Rights Reserved.</p>
            </footer>
            {isConfirm && (
              <div className="xid-confirm-overlay">
                <XIDDeletePage 
                  onBackButtonClick={handleBackButtonClick}
                  errorTextDisplay={errorTextDisplay}
                  successXIDCancel={successXIDCancel}
                />
              </div>
            )}
          </div>
        )}
    </body>
  );
};

export default XIDAliginmentPage;