const resizeAndCompressImage = async (img, targetWidth, targetHeight, maxSize) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
  
      // 計算用の変数
      const aspectRatio = img.width / img.height;
      let width = targetWidth;
      let height = targetHeight;
  
      // アスペクト比を保持しながらリサイズ
      if (aspectRatio > 1) {
        height = targetHeight;
        width = targetHeight * aspectRatio;
      } else {
        width = targetWidth;
        height = targetWidth / aspectRatio;
      }
  
      // リサイズ後の画像を描画
      canvas.width = targetWidth;
      canvas.height = targetHeight;
      ctx.drawImage(
        img,
        (targetWidth - width) / 2,
        (targetHeight - height) / 2,
        width,
        height
      );
  
      // 画像をPNG形式で圧縮
      const compressImage = () => {
        return new Promise((resolve) => {
          canvas.toBlob((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              resolve(reader.result);
            };
          }, 'image/png');
        });
      };
  
      const checkSizeAndCompress = async () => {
        let compressedImage = await compressImage();
        let byteString = atob(compressedImage.split(',')[1]);
        let byteLength = byteString.length;
  
        // サイズが0.8MBを超える場合、再圧縮
        while (byteLength > maxSize) {
          const imgElement = new Image();
          imgElement.src = compressedImage;
          await new Promise((resolve) => (imgElement.onload = resolve));
  
          // 再度リサイズして圧縮
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(
            imgElement,
            (targetWidth - width) / 2,
            (targetHeight - height) / 2,
            width,
            height
          );
          compressedImage = await compressImage();
          byteString = atob(compressedImage.split(',')[1]);
          byteLength = byteString.length;
        }
  
        resolve(compressedImage);
      };
  
      checkSizeAndCompress().catch(reject);
    });
  };

//ファイル読み込んで、画像のサイズを編集
export const handleFileInput = async (event, setIsButtonDisabled, setDefaultFlag, setSelectedImage, setBase64Image) => {
    const file = event.target.files[0];
    if (file) {
      setIsButtonDisabled(false);
      setDefaultFlag(false);
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = async () =>{
          const compressedImage = await resizeAndCompressImage(img, 400, 400 , 0.8 * 1024 * 1024); 
          //これはS3に入れるため、Base64に変換
          const base64Image = compressedImage.split(',')[1];
          setSelectedImage(compressedImage);
          setBase64Image(base64Image);
        };
      };
      reader.readAsDataURL(file);
    }
  };