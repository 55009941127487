export const UpdateProfile = async (family_name, given_name, phone_number) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/registUserInfo/registUserInfo`, {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify({
              userAttributes: [
                { Name: "family_name", Value: family_name},
                { Name: "given_name", Value: given_name},
                { Name: "custom:phone", Value: phone_number}
              ],
              pattern:'regist_info'
            }),
            credentials: 'include'
        });
        return response;
    } catch (error) {
        return error;
    }
};