//Reactのインポート
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';

//CSSのインポート
import './NickNamePage.css';

//マテリアルデザインのインポート
import { Button } from '@mui/material';
import { TextField } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';

//componentのインポート
import {isValidNickname} from '../../../utils/validation.js'
import { nickNameChange } from './controller/NickNameChangeButton.jsx';
import { inputStyles, inputStylesMail } from '../../../componets/TextField.jsx'

//画像のインポート
import backgroundImage from '../../../assets/backgroun-image.png'


function NickNamePage(){
  const currentNickName = sessionStorage.getItem('nickname');
  const [newNickName, setNewNickName] = useState('');
  const [newNickNameError, setNewNickNameError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('ニックネームの変更に失敗しました。');
  const [errorCheck, setErrorCheck] = useState(false);
  const [isFooterVisible, setIsFooterVisible] = useState(true);

  const navigate = useNavigate();
  const navigateToAccount = () => {
    setIsComplete(false);
    navigate('/account');
  };

  // フォーカスが当たったときにfooterを隠す
  const handleFocus = () => {
    setIsFooterVisible(false);
    setTimeout(() => {
      setIsFooterVisible(false);
    }, 350);
  };
  // フォーカスがはずれたときにfooterを表示
  const handleBlur = () => {
    setTimeout(() => {
      setIsFooterVisible(true);
    }, 300);
  };

  //変更ボタンをクリックしたときに実行
  const changeNickNameButtonClick = async () => {
    const sanitizedNickName = DOMPurify.sanitize(newNickName);
    await nickNameChange(setIsLoading, sanitizedNickName, setIsComplete, setErrorMessage, setErrorCheck);
  };

  //入力されたコードのチェック
  useEffect(() => {
    if(newNickName.length > 0){
      if (newNickNameError) {
        setIsButtonDisabled(true);
      } else {
        setIsButtonDisabled(false);
      }
    }
  }, [newNickNameError,newNickName]);

  //入力されたコードのチェック
  useEffect(() => {
    if(newNickName.length > 0){
      setNewNickNameError(!isValidNickname(newNickName));
    }
  }, [newNickName]);

  return (
    <body>
      <img src={backgroundImage} class="common-side-image-left" alt="Side"></img>
      <img src={backgroundImage} class="common-side-image-right" alt="Side"></img>
      <div className='editnickname-page'>
        <div className='common-list-container-title'>
          <label>ニックネーム変更</label>
        </div>
        {!isComplete && (
          <div className='common-list-container-text'>
            <label>変更するニックネームを入力してください</label>
          </div>
        )}
        {isComplete && (
          <div className='common-list-container-text'>
              <label>ニックネームの変更が完了しました。</label>
          </div>
        )}
        {!isComplete && (
          <div className='input-container-nickname'>
            {errorCheck && 
              <div className='error-massage'>
                {errorMessage}
              </div>
            }
            <label htmlFor="email">
                現在のニックネーム
            </label>
            <TextField
              type="text"
              id="oldNick"
              value={currentNickName}
              fullWidth
              disabled
              slotProps={{
                input: {
                  sx: inputStylesMail
                },
              }}
            />
            <label htmlFor="email">
                変更後ニックネーム
            </label>
            <TextField
              type="text"
              id="newNick"
              value={newNickName}
              onChange={(e) => setNewNickName(e.target.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="新しいニックネーム"
              fullWidth
              slotProps={{
                input: {
                  sx: inputStyles
                },
              }}
              className={newNickNameError ? '' : 'textfield-margin-bottom'} 
            />
            {newNickNameError && (
              <FormHelperText error className='form-helper-text-nickname'>
                ニックネームは10文字以内の（漢字・ひらがな・かたかな・半角英数字・_・-のみ利用可能）
              </FormHelperText>
            )}
          </div>
        )}
        {!isComplete && (
          <Button className="nickname-change-button" onClick={changeNickNameButtonClick} disabled={isButtonDisabled}>変更する</Button>
        )}
        {!isComplete && (
          <Button className="nickname-back-button" onClick={navigateToAccount}>戻る</Button>
        )}
        {isComplete && (
          <Button className="nickname-back-button-iscomplete" onClick={navigateToAccount}>戻る</Button>
        )}
        {isLoading && (
          <div className="common-loading-overlay">
            <h2>ユーザー情報を変更中です。しばらくお待ちください。</h2>
            <div className="common-loader"></div>
          </div>
        )}
        {isFooterVisible && (
          <footer className='common-footer'>
            <p>Copyright©City of Sendai All Rights Reserved.</p>
          </footer> 
        )}
      </div>
    </body>
  );
};

export default NickNamePage;