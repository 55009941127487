// userLogin.js
import { useEffect } from 'react';

export const SetUserInfo = (userInfo, setUserName, setUserImage) => {
    useEffect(() => {
        if (userInfo) {
          const { nickname, picture, providerName, x_flag } = userInfo;
          // セッションストレージ・変数にニックネームを保存
          sessionStorage.setItem('nickname', nickname);
          setUserName(nickname);
    
          // セッションストレージ・変数に写真を保存
          const userPicture = picture ?? `${process.env.REACT_APP_S3_DOMAIN}'/user-icons/shirokuma.png`;
          sessionStorage.setItem('picture', userPicture);
          setUserImage(userPicture);
    
          // セッションストレージ・変数に写真を保存
          sessionStorage.setItem('provider', providerName);
          sessionStorage.setItem('x_flag', x_flag);
        }
    }, [userInfo]);
};